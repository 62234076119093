<template>
  <div class="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationData.currentPage"
      :page-sizes="[10, 20, 30, 40,50,100,500,1000,5000]"
      :page-size="paginationData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paginationData.total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  methods: {
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.$emit('changePaginationSize',val)
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.$emit('changePaginationNum',val)
    },
  },
  props:{
    paginationData:{
      type:Object,
      default:{}
    }
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.block{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>