import { request } from "@/requests";

export const addSequencedPacketExchange = (data) => {
    return request({
        url: '/annotation/sequencedPacketExchange/addSequencedPacketExchange',
        method: 'post',
        data: data
    })
}

// 查询项目的第一个未标注数据
export const selectFirstUnannotatedText = (data) => {
    return request({
        url: '/annotation/annotatedText/selectFirstUnannotatedText/' + data,
        method: 'get'
    })
}

// 查询上一个已经标注的数据
export const selectPreviousAnnotatedText = (itemId, textId) => {
    return request({
        url: '/annotation/annotatedText/selectPreviousAnnotatedText/' + itemId + "/" + textId,
        method: 'get'
    })
}

// 查询下一个未标注的数据
export const selectNextUnannotatedText = (itemId, textId) => {
    return request({
        url: '/annotation/annotatedText/selectNextUnannotatedText/' + itemId + "/" + textId,
        method: 'get'
    })
}

// 根据文本id查询序列分类标注数据
export const selectSequencedPacketExchangeByCondition = (textId, itemId) => {
    return request({
        url: '/annotation/annotatedText/selectSequencedPacketExchangeByCondition/' + textId + "/" + itemId,
        method: 'get'
    })
}

// 查询项目剩余未标注的数据
export const selectUnannotatedTextCount = (itemId) => {
    return request({
        url: '/annotation/annotatedText/selectUnannotatedTextCount/' + itemId,
        method: 'get'
    })
}