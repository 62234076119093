var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sequence-classification-annotation-wrapper"},[_c('div',{staticClass:"retrieval-wrapper"},[_c('div',{staticClass:"back-icon-wrapper"},[_c('i',{staticClass:"el-icon-back",staticStyle:{"font-size":"25px","font-weight":"bold"},on:{"click":_vm.returnBackClick}})]),(!_vm.emptyData)?_c('div',{staticClass:"residue-wrapper"},[_vm._v(" 剩余未标注："+_vm._s(_vm.total)+"条 ")]):_vm._e()]),(!_vm.emptyData)?_c('div',{staticClass:"sequence-classification-annotation"},[_c('div',{staticClass:"text-content-wrapper"},[_c('div',{staticClass:"text-conten-title-wrapper"},[_vm._v("文本内容")]),_c('div',{staticClass:"text-content-detail",domProps:{"innerHTML":_vm._s(_vm.textLabelDetails.text)}})]),_c('div',{staticClass:"label-list-wrapper"},[_c('div',{staticClass:"label-general-title-wrapper"},[_vm._v("标签分类")]),_c('div',{staticClass:"label-wrapper"},_vm._l((_vm.labelList),function(item,index){return _c('div',{key:index,staticClass:"label-select-wrapper"},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(_vm._s(item.labelTypeName))]),_c('el-radio-group',{model:{value:(_vm.selectedLabel[item.labelTypeName]),callback:function ($$v) {_vm.$set(_vm.selectedLabel, item.labelTypeName, $$v)},expression:"selectedLabel[item.labelTypeName]"}},_vm._l((item.labelTextVoList),function(item1,index){return _c('el-radio',{key:index,attrs:{"name":item1.labelName,"label":item1.itemLabelId,"border":""}},[_vm._v(_vm._s(item1.labelName))])}),1)],1)}),0),_c('div',{staticClass:"operation-btn-wrapper"},[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.previousClick}},[_vm._v("上一条")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.nextClick}},[_vm._v("下一条")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.resetClick}},[_vm._v("返回至第一条未标注的数据")])],1),(
          _vm.isManagerOrAuditorByProject == 1 || _vm.isManagerOrAuditorByProject == 3
        )?_c('div',{staticClass:"label-general-title-wrapper",staticStyle:{"margin-top":"20px"}},[_vm._v(" 标注详情 ")]):_vm._e(),(
          _vm.isManagerOrAuditorByProject == 1 || _vm.isManagerOrAuditorByProject == 3
        )?_c('div',{staticClass:"label-wrapper"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.textLabelDetails.sequencedPacketExchangeByLabelerList,"border":""}},[_c('el-table-column',{attrs:{"prop":"labelerName","label":"标注人"}}),_c('el-table-column',{attrs:{"prop":"labelName","label":"标签"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.filteredLabelList(
                  scope.row.sequencedPacketExchangeVoList
                )),function(item,index){return _c('span',{key:index,staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(item))])})}}],null,false,155335111)})],1)],1):_vm._e()])]):_vm._e(),(_vm.emptyData)?_c('el-empty',{staticStyle:{"width":"100%","height":"100%"},attrs:{"image-size":200,"description":_vm.description}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }