import { request } from "@/requests";

export const getAllProjectRole = (data) => {
    return request({
        url: '/annotation/itemRole/selectItemRole',
        method: 'post',
        data
    })
}

// 判断当前用户是否是项目的管理员或者审核员
export const isManagerOrAuditor = (data) => {
    return request({
        url: '/annotation/itemUserRole/isManagerOrAuditor/' + data,
        method: 'get'
    })
}
