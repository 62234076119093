<template>
  <div class="data-set-wrapper">
    <div class="query-operation-wrapper">
      <div class="queryCriteria-wrapper">
        <div class="label-title">标签名称</div>
        <el-input
          placeholder="请输入标签名称"
          v-model="getLabelParams.labelName"
        ></el-input>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">标签标识</div>
        <el-input
          placeholder="请输入标签标识"
          v-model="getLabelParams.labelIdentifier"
        ></el-input>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">标签类型</div>
        <el-select v-model="getLabelParams.labelType" placeholder="请选择">
          <el-option
            v-for="item in labelTypeOption"
            :key="item.labelTypeId"
            :label="item.labelTypeName"
            :value="item.labelTypeId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="query-button-wrapper">
        <el-button type="primary" @click="queryClick">查询</el-button>
        <el-button type="primary" style="margin-left: 30px" @click="resetClick"
          >重置</el-button
        >
      </div>
    </div>
    <div class="operation-button-wrapper">
      <el-button type="primary" icon="el-icon-plus" @click="addClick"
        >添加</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table :data="tableData" border height="90%" style="width: 100%">
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="labelName" label="标签名称" width="180">
        </el-table-column>
        <el-table-column prop="labelIdentifier" label="标签标识">
        </el-table-column>
        <el-table-column prop="labelTypeName" label="标签类型">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <!-- <el-button
                type="text"
                style="margin-right: 10px"
                @click="viewUserClick(scope.row.userId)"
                ><i class="el-icon-view"></i>查看</el-button
              > -->
              <el-button
                type="text"
                style="margin: 0 20px"
                @click="editUserClick(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button type="text" @click="deleteUserClick(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        @closed="closeDialogHandle"
        width="25%"
      >
        <div class="dialog-content-wrapper">
          <div class="dialog-line-wrapper">
            <div class="dialog-line-label-tile">标签名称：</div>
            <el-input
              placeholder="请输入标签名称"
              v-model="changeLabelParams.labelName"
            ></el-input>
          </div>
          <div class="dialog-line-wrapper">
            <div class="dialog-line-label-tile">标签标识：</div>
            <el-input
              placeholder="请输入标签标识"
              v-model="changeLabelParams.labelIdentifier"
            ></el-input>
          </div>
          <div class="dialog-line-wrapper">
            <div class="dialog-line-label-tile">标签类型：</div>
            <el-select
              v-model="changeLabelParams.labelTypeId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in labelTypeOption"
                :key="item.labelTypeId"
                :label="item.labelTypeName"
                :value="item.labelTypeId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogSureClick">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import paginationView from "@/components/PaginationView.vue";
import {
  selectItemLabel,
  addItemLabel,
  updateItemLabel,
  deleteItemLabel,
} from "@/api/projectLabel";
import { selectAllLabelType } from "@/api/labelTypeManage";
export default {
  data() {
    return {
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      getLabelParams: {
        pageNum: 1,
        pageSize: 10,
        itemId: 0,
        labelName: "",
        labelIdentifier: "",
        labelType: "",
      },
      changeLabelParams: {
        itemLabelId: 0,
        itemId: 0,
        labelName: "",
        labelIdentifier: "",
        labelTypeId: "",
      },
      labelTypeOption: [],
      title: "",
      tableData: [],
      projectId: 0,
      dialogVisible: false,
      flag: 0,
    };
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.projectId = Number(query.id);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    paginationView,
  },
  mounted() {
    this.getLabelDataPage();
    this.getLabelType();
  },
  methods: {
    async getLabelType() {
      await selectAllLabelType(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.labelTypeOption = res.data;
        }
      });
    },
    changePaginationSize(val) {
      this.projectPersonParams.pageSize = val;
      this.getLabelDataPage();
    },
    changePaginationNum(val) {
      this.projectPersonParams.pageNum = val;
      this.getLabelDataPage();
    },
    async getLabelDataPage() {
      this.getLabelParams.itemId = this.projectId;
      await selectItemLabel(this.getLabelParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    addClick() {
      this.flag = 1;
      this.title = "新增标签";
      this.dialogVisible = true;
    },
    editUserClick(row) {
      this.flag = 2;
      this.title = "修改标签";
      this.dialogVisible = true;
      //console.log(row);
      this.changeLabelParams.itemLabelId = row.itemLabelId;
      this.changeLabelParams.labelName = row.labelName;
      this.changeLabelParams.labelIdentifier = row.labelIdentifier;
      this.changeLabelParams.labelTypeId = row.labelTypeId;
    },
    deleteUserClick(row) {
      //console.log(row);
      let array = []
      array.push(row.itemLabelId)
      // let params = {
      //   itemLabelId: row.itemLabelId,
      //   itemId: this.projectId,
      //   labelName: row.labelName,
      //   labelIdentifier: row.labelIdentifier,
      // };
      this.$confirm("是否要删除此标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteItemLabel(array).then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getLabelDataPage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async dialogSureClick() {
      if (!this.changeLabelParams.labelName) {
        this.$message({
          type: "warning",
          message: "请输入标签名称!",
        });
        return;
      }
      // if (!this.changeLabelParams.labelIdentifier) {
      //   this.$message({
      //     type: "warning",
      //     message: "请填写标签标识!",
      //   });
      //   return;
      // }
      if (!this.changeLabelParams.labelTypeId) {
        this.$message({
          type: "warning",
          message: "请选择标签类型!",
        });
        return;
      }
      if (!this.changeLabelParams.labelName) {
        this.$message({
          type: "warning",
          message: "请输入标签名称!",
        });
        return;
      }
      if (this.flag == 1) {
        this.changeLabelParams.itemId = this.projectId;
        await addItemLabel(this.changeLabelParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.dialogVisible = false;
            this.getLabelDataPage();
          }
        });
      } else if (this.flag == 2) {
        this.changeLabelParams.itemId = this.projectId;
        await updateItemLabel(this.changeLabelParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.dialogVisible = false;
            this.getLabelDataPage();
          }
        });
      }
    },
    closeDialogHandle() {
      this.changeLabelParams = {
        itemLabelId: 0,
        labelName: "",
        labelIdentifier: "",
      };
    },
    queryClick() {
      this.getLabelDataPage();
    },
    resetClick() {
      this.getLabelParams = {
        pageNum: 1,
        pageSize: 10,
        itemId: 0,
        labelName: "",
        labelIdentifier: "",
      };
      this.getLabelDataPage();
    },
  },
};
</script>

<style scoped>
.data-set-wrapper {
  width: 100%;
  height: 100%;
}

.query-operation-wrapper {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.queryCriteria-wrapper {
  display: flex;
  margin-right: 30px;
  align-items: center;
}
.label-title {
  white-space: nowrap; /* 防止换行 */
  margin-right: 10px;
}

.operation-button-wrapper {
  margin: 20px 0;
}

.table-data-wrapper {
  height: 85%;
  overflow-y: scroll;
}

.dialog-line-wrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.dialog-line-label-tile {
  white-space: nowrap; /* 防止换行 */
}
</style>