<template>
  <div class="model-show-wrapper">
    <!-- <div class="upload-button-wrapper">
      <el-button type="primary"
        >上传<i class="el-icon-upload el-icon--right"></i
      ></el-button>
    </div>
    <div class="data-visualization-wrapper">
      <div ref="treeChart" style="width: 100vw; height: 100vh"></div>
    </div> -->
  </div>
</template>

<script>
// 引入 ECharts
import echarts from "echarts";

export default {
  data() {
    return {
      // 假设你的数据是以下格式
      data: [
        {
          name: 152,
          children: [
            {
              id: 76,
              name: 76,
              children: [],
            },
            {
              id: 151,
              name: 151,
              children: [
                {
                  id: 150,
                  name: 150,
                  children: [
                    {
                      id: 51,
                      name: 51,
                      children: [],
                    },
                    {
                      id: 149,
                      name: 149,
                      children: [
                        {
                          id: 55,
                          name: 55,
                          children: [],
                        },
                        {
                          id: 148,
                          name: 148,
                          children: [
                            {
                              id: 147,
                              name: 147,
                              children: [
                                {
                                  id: 65,
                                  name: 65,
                                  children: [],
                                },
                                {
                                  id: 146,
                                  name: 146,
                                  children: [
                                    {
                                      id: 145,
                                      name: 145,
                                      children: [
                                        {
                                          id: 57,
                                          name: 57,
                                          children: [],
                                        },
                                        {
                                          id: 144,
                                          name: 144,
                                          children: [
                                            {
                                              id: 68,
                                              name: 68,
                                              children: [],
                                            },
                                            {
                                              id: 143,
                                              name: 143,
                                              children: [
                                                {
                                                  id: 142,
                                                  name: 142,
                                                  children: [
                                                    {
                                                      id: 141,
                                                      name: 141,
                                                      children: [
                                                        {
                                                          id: 7,
                                                          name: 7,
                                                          children: [],
                                                        },
                                                        {
                                                          id: 140,
                                                          name: 140,
                                                          children: [
                                                            {
                                                              id: 139,
                                                              name: 139,
                                                              children: [
                                                                {
                                                                  id: 138,
                                                                  name: 138,
                                                                  children: [
                                                                    {
                                                                      id: 137,
                                                                      name: 137,
                                                                      children:
                                                                        [
                                                                          {
                                                                            id: 28,
                                                                            name: 28,
                                                                            children:
                                                                              [],
                                                                          },
                                                                          {
                                                                            id: 136,
                                                                            name: 136,
                                                                            children:
                                                                              [
                                                                                {
                                                                                  id: 135,
                                                                                  name: 135,
                                                                                  children:
                                                                                    [
                                                                                      {
                                                                                        id: 134,
                                                                                        name: 134,
                                                                                        children:
                                                                                          [
                                                                                            {
                                                                                              id: 66,
                                                                                              name: 66,
                                                                                              children:
                                                                                                [],
                                                                                            },
                                                                                            {
                                                                                              id: 133,
                                                                                              name: 133,
                                                                                              children:
                                                                                                [
                                                                                                  {
                                                                                                    id: 132,
                                                                                                    name: 132,
                                                                                                    children:
                                                                                                      [
                                                                                                        {
                                                                                                          id: 127,
                                                                                                          name: 127,
                                                                                                          children:
                                                                                                            [
                                                                                                              {
                                                                                                                id: 8,
                                                                                                                name: 8,
                                                                                                                children:
                                                                                                                  [],
                                                                                                              },
                                                                                                              {
                                                                                                                id: 42,
                                                                                                                name: 42,
                                                                                                                children:
                                                                                                                  [],
                                                                                                              },
                                                                                                            ],
                                                                                                        },
                                                                                                        {
                                                                                                          id: 131,
                                                                                                          name: 131,
                                                                                                          children:
                                                                                                            [
                                                                                                              {
                                                                                                                id: 54,
                                                                                                                name: 54,
                                                                                                                children:
                                                                                                                  [],
                                                                                                              },
                                                                                                              {
                                                                                                                id: 130,
                                                                                                                name: 130,
                                                                                                                children:
                                                                                                                  [
                                                                                                                    {
                                                                                                                      id: 129,
                                                                                                                      name: 129,
                                                                                                                      children:
                                                                                                                        [
                                                                                                                          {
                                                                                                                            id: 128,
                                                                                                                            name: 128,
                                                                                                                            children:
                                                                                                                              [
                                                                                                                                {
                                                                                                                                  id: 126,
                                                                                                                                  name: 126,
                                                                                                                                  children:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        id: 125,
                                                                                                                                        name: 125,
                                                                                                                                        children:
                                                                                                                                          [
                                                                                                                                            {
                                                                                                                                              id: 11,
                                                                                                                                              name: 11,
                                                                                                                                              children:
                                                                                                                                                [],
                                                                                                                                            },
                                                                                                                                            {
                                                                                                                                              id: 124,
                                                                                                                                              name: 124,
                                                                                                                                              children:
                                                                                                                                                [
                                                                                                                                                  {
                                                                                                                                                    id: 123,
                                                                                                                                                    name: 123,
                                                                                                                                                    children:
                                                                                                                                                      [
                                                                                                                                                        {
                                                                                                                                                          id: 1,
                                                                                                                                                          name: 1,
                                                                                                                                                          children:
                                                                                                                                                            [],
                                                                                                                                                        },
                                                                                                                                                        {
                                                                                                                                                          id: 122,
                                                                                                                                                          name: 122,
                                                                                                                                                          children:
                                                                                                                                                            [
                                                                                                                                                              {
                                                                                                                                                                id: 19,
                                                                                                                                                                name: 19,
                                                                                                                                                                children:
                                                                                                                                                                  [],
                                                                                                                                                              },
                                                                                                                                                              {
                                                                                                                                                                id: 121,
                                                                                                                                                                name: 121,
                                                                                                                                                                children:
                                                                                                                                                                  [
                                                                                                                                                                    {
                                                                                                                                                                      id: 64,
                                                                                                                                                                      name: 64,
                                                                                                                                                                      children:
                                                                                                                                                                        [],
                                                                                                                                                                    },
                                                                                                                                                                    {
                                                                                                                                                                      id: 120,
                                                                                                                                                                      name: 120,
                                                                                                                                                                      children:
                                                                                                                                                                        [
                                                                                                                                                                          {
                                                                                                                                                                            id: 13,
                                                                                                                                                                            name: 13,
                                                                                                                                                                            children:
                                                                                                                                                                              [],
                                                                                                                                                                          },
                                                                                                                                                                          {
                                                                                                                                                                            id: 119,
                                                                                                                                                                            name: 119,
                                                                                                                                                                            children:
                                                                                                                                                                              [
                                                                                                                                                                                {
                                                                                                                                                                                  id: 118,
                                                                                                                                                                                  name: 118,
                                                                                                                                                                                  children:
                                                                                                                                                                                    [
                                                                                                                                                                                      {
                                                                                                                                                                                        id: 116,
                                                                                                                                                                                        name: 116,
                                                                                                                                                                                        children:
                                                                                                                                                                                          [
                                                                                                                                                                                            {
                                                                                                                                                                                              id: 115,
                                                                                                                                                                                              name: 115,
                                                                                                                                                                                              children:
                                                                                                                                                                                                [
                                                                                                                                                                                                  {
                                                                                                                                                                                                    id: 73,
                                                                                                                                                                                                    name: 73,
                                                                                                                                                                                                    children:
                                                                                                                                                                                                      [],
                                                                                                                                                                                                  },
                                                                                                                                                                                                  {
                                                                                                                                                                                                    id: 114,
                                                                                                                                                                                                    name: 114,
                                                                                                                                                                                                    children:
                                                                                                                                                                                                      [
                                                                                                                                                                                                        {
                                                                                                                                                                                                          id: 113,
                                                                                                                                                                                                          name: 113,
                                                                                                                                                                                                          children:
                                                                                                                                                                                                            [
                                                                                                                                                                                                              {
                                                                                                                                                                                                                id: 111,
                                                                                                                                                                                                                name: 111,
                                                                                                                                                                                                                children:
                                                                                                                                                                                                                  [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                      id: 110,
                                                                                                                                                                                                                      name: 110,
                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                        [
                                                                                                                                                                                                                          {
                                                                                                                                                                                                                            id: 23,
                                                                                                                                                                                                                            name: 23,
                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                              [],
                                                                                                                                                                                                                          },
                                                                                                                                                                                                                          {
                                                                                                                                                                                                                            id: 109,
                                                                                                                                                                                                                            name: 109,
                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                              [
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                  id: 108,
                                                                                                                                                                                                                                  name: 108,
                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                        id: 67,
                                                                                                                                                                                                                                        name: 67,
                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                          [],
                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                        id: 107,
                                                                                                                                                                                                                                        name: 107,
                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                          [
                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                              id: 106,
                                                                                                                                                                                                                                              name: 106,
                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                    id: 63,
                                                                                                                                                                                                                                                    name: 63,
                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                      [],
                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                    id: 105,
                                                                                                                                                                                                                                                    name: 105,
                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                      [
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                          id: 95,
                                                                                                                                                                                                                                                          name: 95,
                                                                                                                                                                                                                                                          children:
                                                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                id: 2,
                                                                                                                                                                                                                                                                name: 2,
                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                  [],
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                id: 34,
                                                                                                                                                                                                                                                                name: 34,
                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                  [],
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                          id: 103,
                                                                                                                                                                                                                                                          name: 103,
                                                                                                                                                                                                                                                          children:
                                                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                id: 90,
                                                                                                                                                                                                                                                                name: 90,
                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                  [
                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                      id: 80,
                                                                                                                                                                                                                                                                      name: 80,
                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                            id: 24,
                                                                                                                                                                                                                                                                            name: 24,
                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                              [],
                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                            id: 53,
                                                                                                                                                                                                                                                                            name: 53,
                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                              [],
                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                      id: 22,
                                                                                                                                                                                                                                                                      name: 22,
                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                id: 102,
                                                                                                                                                                                                                                                                name: 102,
                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                  [
                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                      id: 31,
                                                                                                                                                                                                                                                                      name: 31,
                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                      id: 101,
                                                                                                                                                                                                                                                                      name: 101,
                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                            id: 100,
                                                                                                                                                                                                                                                                            name: 100,
                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                              [
                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                  id: 98,
                                                                                                                                                                                                                                                                                  name: 98,
                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                        id: 94,
                                                                                                                                                                                                                                                                                        name: 94,
                                                                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                                                                          [
                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                              id: 9,
                                                                                                                                                                                                                                                                                              name: 9,
                                                                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                                                                [],
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                              id: 5,
                                                                                                                                                                                                                                                                                              name: 5,
                                                                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                                                                [],
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                        id: 97,
                                                                                                                                                                                                                                                                                        name: 97,
                                                                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                                                                          [
                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                              id: 83,
                                                                                                                                                                                                                                                                                              name: 83,
                                                                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                    id: 27,
                                                                                                                                                                                                                                                                                                    name: 27,
                                                                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                                                                      [],
                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                    id: 49,
                                                                                                                                                                                                                                                                                                    name: 49,
                                                                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                                                                      [],
                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                              id: 93,
                                                                                                                                                                                                                                                                                              name: 93,
                                                                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                    id: 44,
                                                                                                                                                                                                                                                                                                    name: 44,
                                                                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                                                                      [],
                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                    id: 91,
                                                                                                                                                                                                                                                                                                    name: 91,
                                                                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                                                                      [
                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                          id: 89,
                                                                                                                                                                                                                                                                                                          name: 89,
                                                                                                                                                                                                                                                                                                          children:
                                                                                                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                id: 82,
                                                                                                                                                                                                                                                                                                                name: 82,
                                                                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                                                                  [
                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                      id: 52,
                                                                                                                                                                                                                                                                                                                      name: 52,
                                                                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                      id: 81,
                                                                                                                                                                                                                                                                                                                      name: 81,
                                                                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                            id: 77,
                                                                                                                                                                                                                                                                                                                            name: 77,
                                                                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                                                                              [
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  id: 20,
                                                                                                                                                                                                                                                                                                                                  name: 20,
                                                                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                                                                    [],
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  id: 17,
                                                                                                                                                                                                                                                                                                                                  name: 17,
                                                                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                                                                    [],
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                            id: 3,
                                                                                                                                                                                                                                                                                                                            name: 3,
                                                                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                                                                              [],
                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                id: 88,
                                                                                                                                                                                                                                                                                                                name: 88,
                                                                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                                                                  [
                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                      id: 59,
                                                                                                                                                                                                                                                                                                                      name: 59,
                                                                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                      id: 87,
                                                                                                                                                                                                                                                                                                                      name: 87,
                                                                                                                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                            id: 86,
                                                                                                                                                                                                                                                                                                                            name: 86,
                                                                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                                                                              [
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  id: 78,
                                                                                                                                                                                                                                                                                                                                  name: 78,
                                                                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                        id: 60,
                                                                                                                                                                                                                                                                                                                                        name: 60,
                                                                                                                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                                                                                                                          [],
                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                        id: 41,
                                                                                                                                                                                                                                                                                                                                        name: 41,
                                                                                                                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                                                                                                                          [],
                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  id: 85,
                                                                                                                                                                                                                                                                                                                                  name: 85,
                                                                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                        id: 33,
                                                                                                                                                                                                                                                                                                                                        name: 33,
                                                                                                                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                                                                                                                          [],
                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                        id: 79,
                                                                                                                                                                                                                                                                                                                                        name: 79,
                                                                                                                                                                                                                                                                                                                                        children:
                                                                                                                                                                                                                                                                                                                                          [
                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                              id: 39,
                                                                                                                                                                                                                                                                                                                                              name: 39,
                                                                                                                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                                                                                                                [],
                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                              id: 46,
                                                                                                                                                                                                                                                                                                                                              name: 46,
                                                                                                                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                                                                                                                [],
                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                            id: 84,
                                                                                                                                                                                                                                                                                                                            name: 84,
                                                                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                                                                              [
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  id: 25,
                                                                                                                                                                                                                                                                                                                                  name: 25,
                                                                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                                                                    [],
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  id: 32,
                                                                                                                                                                                                                                                                                                                                  name: 32,
                                                                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                                                                    [],
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                          id: 15,
                                                                                                                                                                                                                                                                                                          name: 15,
                                                                                                                                                                                                                                                                                                          children:
                                                                                                                                                                                                                                                                                                            [],
                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                  id: 26,
                                                                                                                                                                                                                                                                                  name: 26,
                                                                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                                                                    [],
                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                            id: 70,
                                                                                                                                                                                                                                                                            name: 70,
                                                                                                                                                                                                                                                                            children:
                                                                                                                                                                                                                                                                              [],
                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                              id: 104,
                                                                                                                                                                                                                                              name: 104,
                                                                                                                                                                                                                                              children:
                                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                    id: 99,
                                                                                                                                                                                                                                                    name: 99,
                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                      [
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                          id: 96,
                                                                                                                                                                                                                                                          name: 96,
                                                                                                                                                                                                                                                          children:
                                                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                id: 36,
                                                                                                                                                                                                                                                                name: 36,
                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                  [],
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                id: 56,
                                                                                                                                                                                                                                                                name: 56,
                                                                                                                                                                                                                                                                children:
                                                                                                                                                                                                                                                                  [],
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                          id: 58,
                                                                                                                                                                                                                                                          name: 58,
                                                                                                                                                                                                                                                          children:
                                                                                                                                                                                                                                                            [],
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                    id: 72,
                                                                                                                                                                                                                                                    name: 72,
                                                                                                                                                                                                                                                    children:
                                                                                                                                                                                                                                                      [],
                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                  id: 29,
                                                                                                                                                                                                                                  name: 29,
                                                                                                                                                                                                                                  children:
                                                                                                                                                                                                                                    [],
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                          },
                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                      id: 14,
                                                                                                                                                                                                                      name: 14,
                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                  ],
                                                                                                                                                                                                              },
                                                                                                                                                                                                              {
                                                                                                                                                                                                                id: 112,
                                                                                                                                                                                                                name: 112,
                                                                                                                                                                                                                children:
                                                                                                                                                                                                                  [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                      id: 16,
                                                                                                                                                                                                                      name: 16,
                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                      id: 40,
                                                                                                                                                                                                                      name: 40,
                                                                                                                                                                                                                      children:
                                                                                                                                                                                                                        [],
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                  ],
                                                                                                                                                                                                              },
                                                                                                                                                                                                            ],
                                                                                                                                                                                                        },
                                                                                                                                                                                                        {
                                                                                                                                                                                                          id: 38,
                                                                                                                                                                                                          name: 38,
                                                                                                                                                                                                          children:
                                                                                                                                                                                                            [],
                                                                                                                                                                                                        },
                                                                                                                                                                                                      ],
                                                                                                                                                                                                  },
                                                                                                                                                                                                ],
                                                                                                                                                                                            },
                                                                                                                                                                                            {
                                                                                                                                                                                              id: 71,
                                                                                                                                                                                              name: 71,
                                                                                                                                                                                              children:
                                                                                                                                                                                                [],
                                                                                                                                                                                            },
                                                                                                                                                                                          ],
                                                                                                                                                                                      },
                                                                                                                                                                                      {
                                                                                                                                                                                        id: 48,
                                                                                                                                                                                        name: 48,
                                                                                                                                                                                        children:
                                                                                                                                                                                          [],
                                                                                                                                                                                      },
                                                                                                                                                                                    ],
                                                                                                                                                                                },
                                                                                                                                                                                {
                                                                                                                                                                                  id: 92,
                                                                                                                                                                                  name: 92,
                                                                                                                                                                                  children:
                                                                                                                                                                                    [
                                                                                                                                                                                      {
                                                                                                                                                                                        id: 75,
                                                                                                                                                                                        name: 75,
                                                                                                                                                                                        children:
                                                                                                                                                                                          [],
                                                                                                                                                                                      },
                                                                                                                                                                                      {
                                                                                                                                                                                        id: 43,
                                                                                                                                                                                        name: 43,
                                                                                                                                                                                        children:
                                                                                                                                                                                          [],
                                                                                                                                                                                      },
                                                                                                                                                                                    ],
                                                                                                                                                                                },
                                                                                                                                                                              ],
                                                                                                                                                                          },
                                                                                                                                                                        ],
                                                                                                                                                                    },
                                                                                                                                                                  ],
                                                                                                                                                              },
                                                                                                                                                            ],
                                                                                                                                                        },
                                                                                                                                                      ],
                                                                                                                                                  },
                                                                                                                                                  {
                                                                                                                                                    id: 10,
                                                                                                                                                    name: 10,
                                                                                                                                                    children:
                                                                                                                                                      [],
                                                                                                                                                  },
                                                                                                                                                ],
                                                                                                                                            },
                                                                                                                                          ],
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        id: 61,
                                                                                                                                        name: 61,
                                                                                                                                        children:
                                                                                                                                          [],
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                                {
                                                                                                                                  id: 35,
                                                                                                                                  name: 35,
                                                                                                                                  children:
                                                                                                                                    [],
                                                                                                                                },
                                                                                                                              ],
                                                                                                                          },
                                                                                                                          {
                                                                                                                            id: 117,
                                                                                                                            name: 117,
                                                                                                                            children:
                                                                                                                              [
                                                                                                                                {
                                                                                                                                  id: 37,
                                                                                                                                  name: 37,
                                                                                                                                  children:
                                                                                                                                    [],
                                                                                                                                },
                                                                                                                              ],
                                                                                                                          },
                                                                                                                        ],
                                                                                                                    },
                                                                                                                    {
                                                                                                                      id: 21,
                                                                                                                      name: 21,
                                                                                                                      children:
                                                                                                                        [],
                                                                                                                    },
                                                                                                                  ],
                                                                                                              },
                                                                                                            ],
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                                  {
                                                                                                    id: 47,
                                                                                                    name: 47,
                                                                                                    children:
                                                                                                      [],
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          ],
                                                                                      },
                                                                                      {
                                                                                        id: 6,
                                                                                        name: 6,
                                                                                        children:
                                                                                          [],
                                                                                      },
                                                                                    ],
                                                                                },
                                                                                {
                                                                                  id: 45,
                                                                                  name: 45,
                                                                                  children:
                                                                                    [],
                                                                                },
                                                                              ],
                                                                          },
                                                                        ],
                                                                    },
                                                                    {
                                                                      id: 4,
                                                                      name: 4,
                                                                      children:
                                                                        [],
                                                                    },
                                                                  ],
                                                                },
                                                                {
                                                                  id: 69,
                                                                  name: 69,
                                                                  children: [],
                                                                },
                                                              ],
                                                            },
                                                            {
                                                              id: 62,
                                                              name: 62,
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      id: 18,
                                                      name: 18,
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  id: 12,
                                                  name: 12,
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      id: 30,
                                      name: 30,
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              id: 50,
                              name: 50,
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 74,
                  name: 74,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // 将数据转换为适合 ECharts 树状图的格式
    // var treeData = [];
    // this.data.forEach((item) => {
    //   treeData.push({
    //     name: String(item.Parent_ID),
    //     children: [
    //       { name: String(item.Child_Left_ID) },
    //       { name: String(item.Child_Right_ID) },
    //     ],
    //   });
    // });
    // //console.log(treeData);
    // 初始化 ECharts 实例
    this.myChart = echarts.init(this.$refs.treeChart);

    // 配置项
    // 配置项
    var option = {
      series: [
        {
          type: "tree",
          data: this.data, // Corrected this line
          left: "2%",
          right: "2%",
          top: "8%",
          bottom: "20%",
          symbol: "emptyCircle",
          orient: "vertical",
          expandAndCollapse: true,
          label: {
            position: "insideTop",
            rotate: -90,
            verticalAlign: "middle",
            align: "right",
            fontSize: 9,
          },
          leaves: {
            label: {
              position: "top",
              rotate: -90,
              verticalAlign: "middle",
              align: "left",
            },
          },
          animationDurationUpdate: 750,
        },
      ],
    };

    // 使用配置项设置图表
    this.myChart.setOption(option);
  },
  beforeDestroy() {
    // 销毁 ECharts 实例，防止内存泄漏
    if (this.myChart) {
      this.myChart.dispose();
    }
  },
};
</script>

<style scoped>
.model-show-wrapper {
  width: 100%;
  height: 100%;
}

.data-visualization-wrapper {
  width: 100%;
  margin-top: 20px;
}
</style>