import Vue from "vue";
import Router from "vue-router";
import login from "../views/Login.vue";
import home from "../views/Home.vue";
import userManage from "../views/UserManage.vue";
import roleManager from "../views/RoleManage.vue";
import demoShow from "../views/DemoShow.vue";
import sequenceClassification from "../views/SequenceClassification.vue";
import sequenceClassificationAnnotation from "@/components/SequenceClassificationAnnotation.vue"
import userInfo from "@/views/UserInfo.vue"
import projectDetail from "@/views/ProjectDetail.vue"
import loginLog from "@/views/LoginLog.vue"
import operationLog from "@/views/OperationLog.vue"
import relationClassification from "@/views/RelationClassification.vue"
import sequenceLabeling from "@/views/SequenceLabeling.vue"
import sequenceToSequence from "@/views/SequenceToSequence.vue"
import examine from "@/components/Examine.vue"

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/home",
        },
        {
            path: "/login",
            name: "login",
            component: login,
        },
        {
            path: "/home",
            name: "home",
            redirect: "/sequenceClassification",
            component: home,
            children: [
                {
                    path: "/userManage",
                    name: "userManage",
                    component: userManage,
                },
                {
                    path: "/roleManager",
                    name: "roleManager",
                    component: roleManager,
                },
                {
                    path: "/demoShow",
                    name: "demoShow",
                    component: demoShow,
                },
                {
                    path: "/sequenceClassification",
                    name: "sequenceClassification",
                    component: sequenceClassification,
                },
                {
                    path: "/sequenceClassificationAnnotation",
                    name: "sequenceClassificationAnnotation",
                    component: sequenceClassificationAnnotation,
                },
                {
                    path: "/userInfo",
                    name: "userInfo",
                    component: userInfo,
                },
                {
                    path: "/projectDetail",
                    name: "projectDetail",
                    component: projectDetail,
                },
                {
                    path: "/operationLog",
                    name: "operationLog",
                    component: operationLog,
                },
                {
                    path: "/loginLog",
                    name: "loginLog",
                    component: loginLog,
                },
                {
                    path: "/relationClassification",
                    name: "relationClassification",
                    component: relationClassification,
                },
                {
                    path: "/sequenceLabeling",
                    name: "sequenceLabeling",
                    component: sequenceLabeling,
                },
                {
                    path: "/sequenceToSequence",
                    name: "sequenceToSequence",
                    component: sequenceToSequence,
                },
                {
                    path: "/examine",
                    name: "examine",
                    component: examine,
                },
            ]
        },
    ]
});
