import { request } from "@/requests";

export const selectSequencedPacketExchangeAnnotatedTextByCondition = (data) => {
    return request({
        url: '/annotation/annotatedText/selectSequencedPacketExchangeAnnotatedTextByCondition',
        method: 'post',
        data
    })
}

export const deleteAnnotatedText = (data) => {
    return request({
        url: '/annotation/annotatedText/deleteAnnotatedText',
        method: 'post',
        data
    })
}

export const selectAllDeletedAnnotatedText = (data) => {
    return request({
        url: '/annotation/annotatedText/selectAllDeletedAnnotatedText',
        method: 'post',
        data
    })
}

export const recoverDeletedAnnotatedText = (data) => {
    return request({
        url: '/annotation/annotatedText/recoverDeletedAnnotatedText',
        method: 'post',
        data
    })
}

export const selectSequencedPacketExchangeAnnotatedTextById = (data) => {
    return request({
        url: '/annotation/annotatedText/selectSequencedPacketExchangeAnnotatedTextById/' + data,
        method: 'get'
    })
}

export const updateSequencedPacketExchangeAnnotatedTextById = (data) => {
    return request({
        url: '/annotation/annotatedText/updateSequencedPacketExchangeAnnotatedTextById',
        method: 'post',
        data
    })
}


// 选择分配数据集
export const selectRangeDataSet = (data) => {
    return request({
        url: '/annotation/annotatedTextLabel/selectRangeDataSet',
        method: 'post',
        data
    })
}

// 均分数据集
export const averageDataSet = (data) => {
    return request({
        url: '/annotation/annotatedTextLabel/averageDataSet',
        method: 'post',
        data
    })
}

