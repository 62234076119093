import { request } from "@/requests";

export const getAllRoleData = (data) => {
    return request({
        url: '/system/sysRole/selectRole',
        method: 'post',
        data
    })
}

export const getRoleById = (data) => {
    return request({
        url: '/system/sysRole/selectRoleById/' + data,
        method: 'get'
    })
}

export const updateRole = (data) => {
    return request({
        url: '/system/sysRole/updateRole',
        method: 'post',
        data
    })
}

export const addRole = (data) => {
    return request({
        url: '/system/sysRole/addRole',
        method: 'post',
        data
    })
}

export const deleteRole = (data) => {
    return request({
        url: '/system/sysRole/deleteRole/' + data,
        method: 'get'
    })
}