import { request } from "@/requests";

export const getAllUserData = (data) => {
    return request({
        url: '/system/sysUser/selectUser',
        method: 'post',
        data:data
    })
}

export const getProjectPerson = () => {
    return request({
        url: '/system/sysUser/selectUsernameList',
        method: 'get',
    })
}

export const getUserData = (data) => {
    return request({
        url: '/system/sysUser/selectUserDetail/' + data,
        method: 'get',
    })
}

export const getMyInfo = () => {
    return request({
        url: '/system/sysUser/getUserInfo',
        method: 'get',
    })
}

export const addUser = (data) => {
    return request({
        url: '/system/sysUser/addUser',
        method: 'post',
        data:data
    })
}

export const deleteUser = (data) => {
    return request({
        url: '/system/sysUser/deleteUser/' + data,
        method: 'get',
    })
}

export const updateUser = (data) => {
    return request({
        url: '/system/sysUser/updateUser',
        method: 'post',
        data:data
    })
}

export const updateMyUserBaseInfo = (data) => {
    return request({
        url: '/system/sysUser/updateMyUserInfo',
        method: 'post',
        data:data
    })
}

export const updateMyUserPassword = (data) => {
    return request({
        url: '/system/sysUser/updateMyPassword',
        method: 'post',
        data:data
    })
}

export const batchDeleteUser = (data) => {
    return request({
        url: '/system/sysUser/deleteUsers',
        method: 'post',
        data
    })
}

export const updateUserStatus = (data1,data2) => {
    return request({
        url: '/system/sysUser/updateUserStatus/' + data1 + "/" + data2,
        method: 'get'
    })
}