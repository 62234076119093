<template>
  <div class="label-type-manage-all-wrapper">
    <div class="operation-button-wrapper">
      <div class="search-wrapper">
        <div class="search-condition-wrapper">
          <span>标签类型名称：</span>
          <el-input
            type="text"
            v-model="labelTypeParams.labelTypeName"
            placeholder="请输入标签类型名称"
          ></el-input>
        </div>
        <div class="search-condition-wrapper">
          <el-button type="primary" style="margin-right: 20px">查询</el-button>
          <el-button type="primary">重置</el-button>
        </div>
      </div>
      <div class="add-button-wrapper">
        <el-button type="primary" @click="addClick">新增</el-button>
      </div>
    </div>
    <div class="label-type-table-wrapper">
      <el-table :data="tableData" border height="90%" style="width: 100%">
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="labelTypeName" label="标签类型名称" width="180">
        </el-table-column>
        <el-table-column prop="description" label="描述"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <!-- <el-button
                type="text"
                style="margin-right: 10px"
                @click="viewUserClick(scope.row.userId)"
                ><i class="el-icon-view"></i>查看</el-button
              > -->
              <el-button
                type="text"
                style="margin: 0 20px"
                @click="editLabelTypeClick(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button type="text" @click="deleteLabelTypeClick(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
        <paginationView
          :paginationData="paginationData"
          @changePaginationSize="changePaginationSize"
          @changePaginationNum="changePaginationNum"
        ></paginationView>
      </el-table>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        @closed="closeDialogHandle"
        width="25%"
      >
        <div class="dialog-content-wrapper">
          <div class="dialog-line-wrapper">
            <div class="dialog-line-label-tile">标签类型名称：</div>
            <el-input
              placeholder="请输入标签类型名称"
              v-model="changeLabelTypeParams.labelTypeName"
              @input="changeInput($event)"
            ></el-input>
          </div>
          <div class="dialog-line-wrapper">
            <div class="dialog-line-label-tile">描述：</div>
            <el-input
              placeholder="请输入"
              v-model="changeLabelTypeParams.description"
              @input="changeInput($event)"
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogSureClick">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  selectLabelType,
  addLabelType,
  updateLabelType,
  deleteLabelType,
} from "@/api/labelTypeManage";
import paginationView from "@/components/PaginationView.vue";
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      changeLabelTypeParams: {
        labelTypeId: 0,
        labelTypeName: "",
        description: "",
        itemId: 0,
      },
      labelTypeParams: {
        pageNum: 1,
        pageSize: 10,
        labelTypeName: "",
        itemId: 0,
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      title: "",
      projectId: 0,
      flag: 0,
    };
  },
  components: {
    paginationView,
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.projectId = Number(query.id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getLabelTypePage();
  },
  methods: {
    changeInput() {
      this.$forceUpdate();
    },
    changePaginationSize(val) {
      this.labelTypeParams.pageSize = val;
      this.getLabelTypePage();
    },
    changePaginationNum(val) {
      this.labelTypeParams.pageNum = val;
      this.getLabelTypePage();
    },
    closeDialogHandle() {
      this.changeLabelTypeParams = {
        itemLabelId: 0,
        labelName: "",
        labelIdentifier: "",
      };
    },
    addClick() {
      this.title = "新增标签类型";
      this.dialogVisible = true;
      this.flag = 1;
    },
    editLabelTypeClick(row) {
      this.title = "编辑标签类型";
      //console.log(row);
      this.changeLabelTypeParams.labelTypeName = row.labelTypeName;
      this.changeLabelTypeParams.description = row.description;
      this.changeLabelTypeParams.labelTypeId = row.labelTypeId;
      this.dialogVisible = true;
      this.flag = 2;
    },
    deleteLabelTypeClick(row) {
      this.$confirm("是否删除数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let array = [];
          array.push(row.labelTypeId);
          this.deleteLabelTypeMethod(array);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async deleteLabelTypeMethod(value) {
      await deleteLabelType(value).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getLabelTypePage();
        }
      });
    },
    async dialogSureClick() {
      if (!this.changeLabelTypeParams.labelTypeName) {
        this.$message({
          type: "warning",
          message: "请填写标签类型名称！",
        });
        return;
      }
      this.changeLabelTypeParams.itemId = this.projectId;
      if (this.flag == 1) {
        await addLabelType(this.changeLabelTypeParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.getLabelTypePage();
            this.dialogVisible = false;
          }
        });
      } else if (this.flag == 2) {
        await updateLabelType(this.changeLabelTypeParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.getLabelTypePage();
            this.dialogVisible = false;
          }
        });
      }
    },
    async getLabelTypePage() {
      this.labelTypeParams.itemId = this.projectId;
      await selectLabelType(this.labelTypeParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
  },
};
</script>

<style scoped>
.label-type-manage-all-wrapper {
  width: 100%;
  height: 100%;
}
.operation-button-wrapper {
  width: 100%;
  height: 12%;
}
.search-wrapper {
  width: 100%;
  height: 50%;
  display: flex;
}
.search-condition-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap; /* 防止换行 */
  margin-right: 20px;
}
.search-condition-wrapper span {
  margin-right: 5px;
}

.label-type-table-wrapper {
  width: 100%;
  height: 88%;
}

.dialog-line-wrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.dialog-line-label-tile {
  white-space: nowrap; /* 防止换行 */
  width: 150px;
}
</style>