<template>
  <div class="label-data-statistics-all-wrapper">
    <div class="row-wrapper">
      <div class="content-wrapper">
        <div id="StackedBar"></div>
      </div>
      <div class="content-wrapper">
        <div id="pieChart" style="width: 400px; height: 400px"></div>
      </div>
      <div class="content-wrapper">
        <div id="funnelChart" style="width: 400px; height: 400px"></div>
      </div>
    </div>
    <div class="row-wrapper" style="margin-top: 20px">
      <div class="content-wrapper">
        <div id="pieChart2" style="width: 400px; height: 400px"></div>
      </div>
      <div class="content-wrapper">
        <div id="stackedBarChart" style="width: 400px; height: 400px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import {
  selectSequencedPacketExchangeByTextId,
  personnelAssignment,
  labelDistribution,
  labelAbnormal,
  labelerLabelStatistics,
} from "@/api/labelDataStatistics";

export default {
  data() {
    return {
      projectId: 0,
      StackedBarData: [],
      pieData: [],
      funnelChartData: [],
      pieData2: [],
      StackedBarData2: [],
    };
  },
  mounted() {
    this.getPersonnelAnnotationDetails();
    this.getPersonnelAssignment();
    this.getLabelDistribution();
    this.getLabelAbnormal();
    this.getLabelerLabelStatistics();
    this.initChart();
    this.renderPieChart();
    this.renderPieChart2();
    this.renderFunnelChart();
    this.renderStackedBarChart();
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.projectId = Number(query.id);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initChart() {
      let chartDom = document.getElementById("StackedBar");
      let myChart = echarts.init(chartDom);
      // 在这里配置 ECharts 图表的数据和样式
      myChart.setOption({
        title: {
          text: "人员标注详情",
          left: "center",
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: this.StackedBarData[1],
          top: "7%",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.StackedBarData[0],
        },
        yAxis: {
          type: "value",
        },
        series: this.StackedBarData[2],
      });
    },
    renderPieChart() {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("pieChart"));

      // 设置饼图的option
      let options = {
        title: {
          text: "数据分配详情",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        series: [
          {
            name: "标注人员",
            type: "pie",
            // radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: this.pieData,
          },
        ],
      };

      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    renderPieChart2() {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("pieChart2"));

      // 设置饼图的option
      let options = {
        title: {
          text: "数据异常详情",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        series: [
          {
            name: "数据异常详情",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: this.pieData2,
          },
        ],
      };

      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    renderFunnelChart() {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("funnelChart"));

      // 设置漏斗图的option
      let options = {
        title: {
          text: "标签分配详情",
          left: "center",
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}",
        },
        series: [
          {
            name: "标签分布详情",
            type: "funnel",
            left: "10%",
            width: "60%",
            label: {
              show: true,
              position: "inside",
            },
            labelLine: {
              length: 8,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              opacity: 0.7,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: this.funnelChartData,
          },
        ],
      };

      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    renderStackedBarChart() {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("stackedBarChart"));

      // 设置堆叠条形图的option
      let options = {
        title: {
          text: "数据状态",
          left: "center",
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: this.StackedBarData2[1],
          top: "7%",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: this.StackedBarData2[0],
        },
        series: this.StackedBarData2[2],
      };

      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    async getPersonnelAnnotationDetails() {
      this.StackedBarData = [];
      await selectSequencedPacketExchangeByTextId(this.projectId).then(
        (res) => {
          //console.log(res);
          if (res.code == 200) {
            let array = [];
            let dataArr = [];
            res.data?.labelStatistics.map((item) => {
              let obj = {};
              array.push(item.labelName);
              obj.name = item.labelName;
              obj.data = item.labelerCount;
              obj.type = "bar";
              obj.stack = "total";
              dataArr.push(obj);
            });
            this.StackedBarData.push(res.data.labelerNames);
            this.StackedBarData.push(array);
            this.StackedBarData.push(dataArr);
            //console.log(this.StackedBarData);
            this.initChart();
          }
        }
      );
    },
    async getPersonnelAssignment() {
      await personnelAssignment(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          res.data?.map((item) => {
            item.value = item.assignedDataCount;
            item.name = item.labelerName;
          });
          this.pieData = res.data;
          this.renderPieChart();
        }
      });
    },
    async getLabelDistribution() {
      await labelDistribution(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          res.data?.map((item) => {
            item.value = item.labelCount;
            item.name = item.labelName;
          });
          this.funnelChartData = res.data;
          this.renderFunnelChart();
        }
      });
    },
    async getLabelAbnormal() {
      await labelAbnormal(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          res.data?.map((item) => {
            item.value = item.abnormalCount;
            if (item.status == 0) {
              item.name = "正常";
            } else if (item.status == 1) {
              item.name = "异常";
            }
          });
          this.pieData2 = res.data;
          this.renderPieChart2();
        }
      });
    },
    async getLabelerLabelStatistics() {
      this.StackedBarData2 = [];
      await labelerLabelStatistics(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          let array = [];
          let dataArr = [];
          res.data?.stateDataStatisticsVos.map((item) => {
            let obj = {};
            array.push(item.stateName);
            obj.name = item.stateName;
            obj.data = item.count;
            obj.type = "bar";
            obj.stack = "total";
            dataArr.push(obj);
          });
          //console.log(res.data.labelerName);
          //console.log(array);
          //console.log(dataArr);
          debugger;
          this.StackedBarData2.push(res.data.labelerName);
          this.StackedBarData2.push(array);
          this.StackedBarData2.push(dataArr);
          //console.log(this.StackedBarData2);
          this.renderStackedBarChart();
        }
      });
    },
  },
};
</script>

<style scoped>
.label-data-statistics-all-wrapper {
  width: 100%;
  height: 100%;
}

.row-wrapper {
  width: 100%;
  height: calc(50%);
  display: flex;
}

.content-wrapper {
  width: 33.33%; /* 让每个容器占据父容器的1/3宽度 */
  height: 100%;
  padding: 20px;
  box-sizing: border-box; /* 让边框计算在内 */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content-title-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#StackedBar {
  width: 400px;
  height: 400px;
}
</style>