<template>
  <div class="user-manage-wrapper">
    <div class="top_search_wrapper">
      <div class="name_wrapper">
        <div class="label_title">姓名</div>
        <el-input
          v-model="userParam.username"
          placeholder="请输入姓名"
        ></el-input>
      </div>
      <div class="name_wrapper">
        <div class="label_title">电话号码</div>
        <el-input
          v-model="userParam.phoneNumber"
          placeholder="请输入电话号码"
        ></el-input>
      </div>
      <div class="name_wrapper">
        <div class="label_title">状态</div>
        <el-select v-model="userParam.status" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="margin-right: 20px"
        @click="searchUser"
        >查询</el-button
      >
      <el-button type="primary" icon="el-icon-refresh-right" @click="resetClick"
        >重置</el-button
      >
    </div>
    <div class="add-btn-wrapper">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addUserClick"
        style="margin-right: 20px"
        >添加</el-button
      >
      <el-button type="danger" icon="el-icon-delete" @click="batchDeleteClick"
        >批量删除</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table
        :data="tableData"
        border
        height="95%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="username" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="phoneNumber" label="电话号码"> </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-text="有效"
              inactive-text="无效"
              :active-value="0"
              :inactive-value="1"
              @change="changeStatus(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <el-button
                type="text"
                style="margin-right: 10px"
                @click="viewUserClick(scope.row.userId)"
                ><i class="el-icon-view"></i>查看</el-button
              >
              <el-button
                type="text"
                style="margin: 0 20px"
                @click="editUserClick(scope.row.userId)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button type="text" @click="deleteUserClick(scope.row.userId)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
    </div>

    <el-dialog
      :title="userDialogTitle"
      :visible.sync="UserDialogVisible"
      width="60%"
      @open="openUserDialog"
      @closed="closeUserDialog"
    >
      <div class="outermost-container">
        <div class="content-container">
          <div class="label-title-container">姓名</div>
          <el-input
            placeholder="请输入姓名"
            v-model="changeUser.username"
            :disabled="!isOperation"
          ></el-input>
        </div>
        <div class="content-container">
          <div class="label-title-container">电话号码</div>
          <el-input
            placeholder="请输入电话号码"
            v-model="changeUser.phoneNumber"
            :disabled="!isOperation"
          ></el-input>
        </div>
        <div class="content-container">
          <div class="label-title-container">用户角色</div>
          <el-select
            v-model="changeUser.roleId"
            :disabled="!isOperation"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="content-container" v-if="isOperation">
          <div class="label-title-container">密码</div>
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="changeUser.password"
            :disabled="!isOperation"
            show-password
          ></el-input>
        </div>
        <div class="content-container">
          <div class="label-title-container">邮箱</div>
          <el-input
            placeholder="请输入邮箱"
            :disabled="!isOperation"
            v-model="changeUser.email"
          ></el-input>
        </div>
        <div class="content-container">
          <div class="label-title-container">备注</div>
          <el-input
            placeholder="请输入备注"
            :disabled="!isOperation"
            v-model="changeUser.remark"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddUserClick">取 消</el-button>
        <el-button type="primary" @click="sureAddUserClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paginationView from "@/components/PaginationView.vue";
import {
  getAllUserData,
  addUser,
  deleteUser,
  getUserData,
  updateUser,
  batchDeleteUser,
  updateUserStatus,
} from "@/api/user.js";
import { getAllRoleData } from "@/api/role.js";
export default {
  data() {
    return {
      radio1: "1",
      UserDialogVisible: false,
      name: "",
      phone: "",
      flag: 0,
      userParam: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phoneNumber: "",
        email: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      changeUser: {
        username: "",
        avatar: "",
        phoneNumber: "",
        password: "",
        email: "",
        remark: "",
        roleId: null,
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      roleParams: {
        pageNum: 1,
        pageSize: 100,
        roleKey: "",
        roleName: "",
        status: 0,
        startTime: "",
        endTime: "",
      },
      tableData: [],
      options: [],
      value: "",
      multipleSelection: [],
      statusOptions: [
        {
          value: 0,
          label: "有效",
        },
        {
          value: 1,
          label: "失效",
        },
      ],
    };
  },
  components: {
    paginationView,
  },
  computed: {
    userDialogTitle() {
      let title;
      if (this.flag == 0) {
        title = "添加用户";
      } else if (this.flag == 1) {
        title = "查看用户";
      } else if (this.flag == 2) {
        title = "编辑用户";
      }
      return title;
    },
    isOperation() {
      let flag;
      if (this.flag == 0) {
        flag = true;
      } else if (this.flag == 1) {
        flag = false;
      } else if (this.flag == 2) {
        flag = true;
      }
      return flag;
    },
  },
  mounted() {
    this.getUserPage();
  },
  methods: {
    changePaginationSize(val) {
      this.userParam.pageSize = val;
      this.getUserPage();
    },
    changePaginationNum(val) {
      this.userParam.pageNum = val;
      this.getUserPage();
    },
    addUserClick() {
      this.flag = 0;
      this.UserDialogVisible = true;
    },
    viewUserClick(userId) {
      this.flag = 1;
      this.UserDialogVisible = true;
      this.getSingleUserData(userId);
    },
    editUserClick(userId) {
      this.flag = 2;
      this.UserDialogVisible = true;
      this.getSingleUserData(userId);
    },
    deleteUserClick(userId) {
      this.$confirm("是否要删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteUser(userId).then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getUserPage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cancelAddUserClick() {
      this.UserDialogVisible = false;
      this.getUserPage();
    },
    searchUser() {
      this.userParam.pageNum = 1;
      this.userParam.pageSize = 10;
      this.getUserPage();
    },
    resetClick() {
      this.userParam = {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phoneNumber: "",
        email: "",
        status: "",
        startTime: "",
        endTime: "",
      };
      this.getUserPage();
    },
    async getUserPage() {
      await getAllUserData(this.userParam).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    async getRoleData() {
      await getAllRoleData(this.roleParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.options = res.data.list;
        }
      });
    },
    openUserDialog() {
      this.getRoleData();
    },
    closeUserDialog() {
      this.changeUser = {
        username: "",
        avatar: "",
        phoneNumber: "",
        password: "",
        email: "",
        status: "0",
        remark: "",
        roleId: null,
      };
    },
    async sureAddUserClick() {
      var phoneRegex = /^1[3456789]\d{9}$/;
      if (!phoneRegex.test(this.changeUser.phoneNumber)) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
        });
        return;
      }
      if (this.flag == 0) {
        await addUser(this.changeUser).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });

            this.UserDialogVisible = false;
            this.getUserPage();
          }
        });
      } else if (this.flag == 2) {
        await updateUser(this.changeUser).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });

            this.UserDialogVisible = false;
            this.getUserPage();
          }
        });
      }
    },
    async getSingleUserData(userId) {
      await getUserData(userId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.changeUser = { ...res.data };
        }
      });
    },
    handleSelectionChange(val) {
      //console.log(val);
      this.multipleSelection = val;
    },
    async batchDeleteClick() {
      let array = [];
      this.multipleSelection.forEach((item) => {
        array.push(item.userId);
      });
      await batchDeleteUser(array).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message({ type: "success", message: "删除成功" });
          this.getUserPage();
        }
      });
    },
    async changeStatus(item) {
      //console.log(item);
      await updateUserStatus(item.userId, item.status).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getUserPage();
        }
      });
    },
  },
};
</script>

<style scoped>
.user-manage-wrapper {
  width: 100%;
  height: 100%;
}

.top_search_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.name_wrapper {
  margin-right: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap; /* 防止换行 */
}

.label_title {
  margin-right: 10px;
}

.add-btn-wrapper {
  margin-top: 20px;
}

.table-data-wrapper {
  width: 100%;
  margin-top: 30px;
  height: 83%;
}

.el-icon-view,
.el-icon-edit,
.el-icon-delete {
  margin-right: 8px;
}

.content-container {
  margin-bottom: 20px;
}

.label-title-container {
  margin-bottom: 5px;
  font-size: 16px;
}
</style>