<template>
  <div class="sequence-classification-annotation-wrapper">
    <div class="retrieval-wrapper">
      <!-- <div class="retrieval-container">
        <div class="label-title">关键词</div>
        <el-input
          placeholder="请输入关键词"
          style="margin: 0 10px"
          v-model="datasetParams.text"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="margin-left: 30px"
          @click="getData"
          >检索</el-button
        >
      </div> -->
      <div class="back-icon-wrapper">
        <i
          class="el-icon-back"
          style="font-size: 25px; font-weight: bold"
          @click="returnBackClick"
        ></i>
      </div>
      <div v-if="!emptyData" class="residue-wrapper">
        剩余未审核：{{ total }}条
      </div>
    </div>
    <div class="sequence-classification-annotation" v-if="!emptyData">
      <div class="text-content-wrapper">
        <div class="text-conten-title-wrapper">文本内容</div>
        <div class="text-content-detail" v-html="textLabelDetails.text"></div>
      </div>
      <div class="label-list-wrapper">
        <div
          class="label-general-title-wrapper"
          v-if="textLabelDetails.isAudit != 1"
        >
          标签分类<el-button style="margin-left: 20px" @click="cleraSelectClick"
            >清空已选择的标注</el-button
          >
        </div>
        <div class="label-wrapper" v-if="textLabelDetails.isAudit != 1">
          <div
            class="label-select-wrapper"
            v-for="(item, index) in labelList"
            :key="index"
            style="margin-top: 20px"
          >
            <div style="margin-bottom: 5px">{{ item.labelTypeName }}</div>
            <el-radio-group v-model="selectedLabel[index]">
              <el-radio
                :name="item1.labelName"
                :label="item1.itemLabelId"
                border
                v-for="(item1, index) in item.labelTextVoList"
                :key="index"
                >{{ item1.labelName }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="label-general-title-wrapper" style="margin-top: 20px">
          标注详情<span
            style="font-size: 16px; margin-left: 5px; font-weight: 400"
            v-if="textLabelDetails.isAudit != 1"
            >(如若不同意以下标注情况，可自行选择以上标签进行标注)</span
          >
        </div>
        <div class="label-wrapper">
          <el-table
            :data="textLabelDetails.sequencedPacketExchangeByLabelerList"
            border
            style="width: 100%"
          >
            <el-table-column prop="labelerName" label="标注人" width="150">
            </el-table-column>
            <el-table-column
              prop="labelName"
              show-overflow-tooltip
              label="标签"
            >
              <template slot-scope="scope">
                <el-tag
                  style="margin-right: 5px"
                  v-for="(item, index) in scope.row
                    .sequencedPacketExchangeVoList"
                  :key="index"
                  >{{ item.labelName }}</el-tag
                >
              </template>
            </el-table-column>

            <!-- <el-table-column prop="labelTime" label="标注时间">
            </el-table-column> -->
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <div class="custom-cell">
                  <el-button
                    type="text"
                    style="margin-right: 10px"
                    :disabled="disableAgreeBtn"
                    v-if="textLabelDetails.isAudit != 1"
                    @click.stop="agreeClick(scope.row)"
                    >同意</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="operation-btn-wrapper">
            <el-button type="success" @click="previousClick">上一条</el-button>
            <el-button type="success" @click="nextClick">下一条</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-empty
      :image-size="200"
      style="width: 100%; height: 100%"
      v-if="emptyData"
      :description="description"
    ></el-empty>
  </div>
</template>

<script>
import {
  auditAnnotatedText,
  selectUnauditedAnnotatedText,
  selectPreviousAuditedText,
  selectUnauditedTextCount,
  selectNextAuditedText,
} from "@/api/examine";
import {
  addSequencedPacketExchange,
  selectSequencedPacketExchangeByCondition,
} from "@/api/dimension";
import { selectLabelTypeByType } from "@/api/labelTypeManage";
export default {
  data() {
    return {
      datasetParams: {
        pageNum: 1,
        pageSize: 1,
        itemId: 0,
        isShare: 0,
        text: "",
        isLabel: 1,
        isAudit: 0,
      },
      emptyData: false,
      dimensionParams: {
        textId: 0,
        labelIdentifierList: [],
      },
      labelList: [],
      description: "",
      total: 0,
      textLabelDetails: {},
      userId: 0,
      dataLabel: {},
      selectedLabel: [],
      disableAgreeBtn: false,
    };
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.datasetParams.itemId = Number(query.id);
        this.datasetParams.isShare = Number(query.isShare);
        if (query.textId) {
          this.getDataById(query.textId);
        } else {
          this.getData();
        }
        this.getUnauditedTextCount();
      },
      deep: true,
      immediate: true,
    },
    selectedLabel: {
      handler(newValue, oldValue) {
        if (newValue.length != 0) {
          this.disableAgreeBtn = true;
        } else {
          this.disableAgreeBtn = false;
        }
      },
      deep: true, // 深度监听数组内部元素的变化
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    this.userId = Number(localStorage.getItem("userId"));
    this.getLabelList();
  },
  methods: {
    returnBackClick() {
      this.$router.push({
        path: "/projectDetail",
        query: {
          id: this.datasetParams.itemId,
        },
      });
    },
    async getLabelList() {
      await selectLabelTypeByType(this.datasetParams.itemId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.labelList = res.data;
        }
      });
    },
    handleKeyDown(event) {
      // 阻止事件冒泡
      event.stopPropagation();
      //console.log(event);
      //console.log("你按下了：", event.key);
      if (event.key == "d" || event.key == "ArrowRight") {
        this.nextClick();
      } else if (event.key == "a" || event.key == "ArrowLeft") {
        this.previousClick();
      }
    },
    async getData() {
      await selectUnauditedAnnotatedText(this.datasetParams.itemId).then(
        (res) => {
          //console.log(res);
          if (res.code == 200) {
            this.textLabelDetails = res.data;
            this.getDataById(res.data.textId);
            this.getUnauditedTextCount();
          } else if (res.code == 704) {
            this.emptyData = true;
            this.description = "暂无未审核数据";
          }
        }
      );
    },
    async getDataById(id) {
      await selectSequencedPacketExchangeByCondition(
        id,
        this.datasetParams.itemId
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.textLabelDetails = res.data;
          this.selectedLabel = [];
          res.data?.sequencedPacketExchangeByLabelerList.forEach((item) => {
            if (item.labeler == this.userId) {
              item.sequencedPacketExchangeVoList.forEach((item1) => {
                this.selectedLabel.push(item1.itemLabelId);
              });
            }
          });
          this.getUnauditedTextCount();
        }
      });
    },
    async saveDimension() {
      this.dimensionParams.textId = this.textLabelDetails.textId;
      this.dimensionParams.labelIdentifierList = this.checkboxGroup1;
      await addSequencedPacketExchange(this.dimensionParams).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
        }
      });
    },
    async agreeClick(row) {
      //console.log(row);
      let query = {
        textId: row.textId,
        itemId: this.datasetParams.itemId,
        labeler: row.labeler,
        itemLabelIds: [],
        isAuditDate: 0,
      };
      row.sequencedPacketExchangeVoList.map((item) => {
        query.itemLabelIds.push(item.itemLabelId);
      });
      this.agreeAnnotation(query, 1);
    },
    async agreeAnnotation(query, value) {
      //console.log(value);
      await auditAnnotatedText(query).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          if (value == 1) {
            this.getData();
          }
        }
      });
    },
    cleraSelectClick() {
      this.selectedLabel = [];
      this.disableAgreeBtn = false;
    },
    async previousClick() {
      //console.log(this.value);
      if (this.selectedLabel.length > 0) {
        let query = {
          textId: this.textLabelDetails.textId,
          itemId: this.datasetParams.itemId,
          labeler: this.userId,
          itemLabelIds: this.selectedLabel,
          isAuditDate: 1,
        };
        this.agreeAnnotation(query, 2);
      }
      await selectPreviousAuditedText(
        this.datasetParams.itemId,
        this.textLabelDetails.textId
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.textLabelDetails = res.data;
          this.getUnauditedTextCount();
        }
        // else if (res.code == 703) {
        //   this.emptyData = true;
        //   this.description = "暂无上一条已审核数据";
        // }
      });
    },
    async getUnauditedTextCount() {
      await selectUnauditedTextCount(this.datasetParams.itemId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.total = res.data;
        }
      });
    },
    async nextClick() {
      //console.log(this.selectedLabel);
      if (this.selectedLabel.length > 0) {
        let query = {
          textId: this.textLabelDetails.textId,
          itemId: this.datasetParams.itemId,
          labeler: this.userId,
          itemLabelIds: this.selectedLabel,
          isAuditDate: 1,
        };
        this.agreeAnnotation(query, 1);
      } else {
        await selectNextAuditedText(
          this.datasetParams.itemId,
          this.textLabelDetails.textId
        ).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.textLabelDetails = res.data;
            this.getUnauditedTextCount();
          }
          // else if (res.code == 704) {
          //   this.$message.error(res.message);
          // }
        });
      }
    },
  },
};
</script>

<style scoped>
.sequence-classification-annotation-wrapper {
  width: 100%;
  height: 100%;
}

.retrieval-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.retrieval-container {
  display: flex;
  align-items: center;
}

.label-title {
  white-space: nowrap;
}

.sequence-classification-annotation {
  width: 100%;
  display: flex;
}

.label-list-wrapper {
  width: 50%;
}

.text-content-wrapper {
  width: 50%;
  margin-right: 50px;
}

.label-general-title-wrapper,
.text-conten-title-wrapper {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 550;
}

.label-wrapper {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.label-title-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.operation-btn-wrapper {
  margin-top: 20px;
}
</style>