import { request } from "@/requests";

export const selectSequencedPacketExchangeByTextId = (data) => {
    return request({
        url: '/annotation/sequencedPacketExchange/selectSequencedPacketExchangeByTextId/' + data,
        method: 'get'
    })
}

export const auditAnnotatedText = (data) => {
    return request({
        url: '/annotation/annotatedText/auditAnnotatedText',
        method: 'post',
        data
    })
}

// 查询未审核的标注文本
export const selectUnauditedAnnotatedText = (data) => {
    return request({
        url: '/annotation/annotatedText/selectUnauditedAnnotatedText/' + data,
        method: 'get'
    })
}

// 查询下一条未审核的标注文本
export const selectNextAuditedText = (itemId,textId) => {
    return request({
        url: '/annotation/annotatedText/selectNextAuditedText/' + itemId + '/' + textId,
        method: 'get'
    })
}

// 查询上一条审核的标注文本
export const selectPreviousAuditedText = (itemId,textId) => {
    return request({
        url: '/annotation/annotatedText/selectPreviousAuditedText/' + itemId + "/" + textId,
        method: 'get'
    })
}

// 查询项目剩余未审核的数据
export const selectUnauditedTextCount = (itemId) => {
    return request({
        url: '/annotation/annotatedText/selectUnauditedTextCount/' + itemId,
        method: 'get'
    })
}