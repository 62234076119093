// 获取文件格式
export const getFileType = fileUrl =>{
    // 获取文件名或扩展名
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    // 根据扩展名判断文件类型
    switch (fileExtension) {
      case 'xls':
      case 'xlsx':
        return 'excel';
      default:
        return 'other';
    }
  }