<template>
  <div class="dialog-wrapper">
    <el-dialog
      title="新建项目"
      :visible.sync="showDialogVisible"
      :before-close="handleClose"
      @opened="handleOpened"
      width="70%"
    >
      <div class="from-wrapper">
        <div class="from-conten-wrapper">
          <div class="label-title-wrapper">项目名称</div>
          <el-input
            :disabled="!isEdit"
            v-model="addAnnotatedParams.itemName"
            placeholder="请输入项目名称"
          ></el-input>
        </div>
        <div class="from-conten-wrapper">
          <div class="label-title-wrapper">项目描述</div>
          <el-input
            v-model="addAnnotatedParams.description"
            :disabled="!isEdit"
            placeholder="请输入项目描述"
            type="textarea"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelClick">取 消</el-button>
        <el-button type="primary" @click="sureClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addAnnotatedItem,
  updateAnnotatedItem,
  getAnnotatedItemById,
} from "@/api/annotateProjectManagement";
export default {
  data() {
    return {
      addAnnotatedParams: {
        itemName: "",
        annotationType: 0,
        description: "",
      },
    };
  },
  props: {
    showDialogVisible: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: Number,
      default: 0,
    },
    annotationType: {
      type: Number,
      default: 0,
    },
    annotatedItemId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isEdit() {
      let temp = false;
      if (this.flag == 1 || this.flag == 3) {
        temp = true;
      }
      return temp;
    },
  },
  methods: {
    handleOpened() {
      this.addAnnotatedParams = {
        itemName: "",
        annotationType: 0,
        description: "",
      };
      if (this.flag == 2 || this.flag == 3) {
        this.getAnnotatedItemByIdDetail();
      }
    },
    async getAnnotatedItemByIdDetail() {
      await getAnnotatedItemById(this.annotatedItemId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.addAnnotatedParams = { ...res.data };
        }
      });
    },
    handleClose() {
      this.addAnnotatedParams = {
        itemName: "",
        annotationType: 0,
        description: "",
      };
      this.$emit("closeDialogView");
    },
    async sureClick() {
      if (this.flag == 1) {
        this.addAnnotatedParams.annotationType = this.annotationType;
        await addAnnotatedItem(this.addAnnotatedParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.$emit("closeDialogView");
          }
        });
      } else if (this.flag == 2) {
        this.$emit("closeDialogView");
      } else {
        let params = {
          itemId: this.annotatedItemId,
          itemName: this.addAnnotatedParams.itemName,
          description: this.addAnnotatedParams.description,
        };
        await updateAnnotatedItem(params).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$emit("closeDialogView");
          }
        });
      }
    },
    cancelClick() {
      this.$emit("closeDialogView");
    },
  },
};
</script>

<style scoped>
.dialog-wrapper {
  width: 100%;
}
.from-wrapper {
  width: 100%;
}
.from-conten-wrapper {
  margin-bottom: 20px;
}
.label-title-wrapper {
  font-size: 18px;
  margin-bottom: 5px;
}
.el-select {
  margin-left: 0px !important;
}
</style>