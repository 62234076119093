import { request } from "@/requests";

export const selectAllAnnotatedItem = (data) => {
    return request({
        url: '/annotation/annotatedItem/selectAnnotatedItem',
        method: 'post',
        data:data
    })
}

export const addAnnotatedItem = (data) => {
    return request({
        url: '/annotation/annotatedItem/addAnnotatedItem',
        method: 'post',
        data:data
    })
}

export const updateAnnotatedItem = (data) => {
    return request({
        url: '/annotation/annotatedItem/updateAnnotatedItem',
        method: 'post',
        data:data
    })
}

export const getAnnotatedItemById = (data) => {
    return request({
        url: '/annotation/annotatedItem/selectAnnotatedItemDetail/' + data,
        method: 'get',
    })
}

export const deleteAnnotatedItemById = (data) => {
    return request({
        url: '/annotation/annotatedItem/deleteAnnotatedItem',
        method: 'post',
        data
    })
}
