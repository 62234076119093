<template>
  <div class="login-log-wrapper">
    <div class="top_search_wrapper">
      <div class="name_wrapper">
        <div class="label_title">用户名</div>
        <el-input
          v-model="loginLogParams.username"
          placeholder="请输入用户名"
        ></el-input>
      </div>
      <div class="name_wrapper">
        <div class="label_title">电话号码</div>
        <el-input
          v-model="loginLogParams.phoneNumber"
          placeholder="请输入电话号码"
        ></el-input>
      </div>
      <div class="name_wrapper">
        <div class="label_title">状态</div>
        <el-select v-model="loginLogParams.status" placeholder="请选择">
          <el-option
            v-for="item in loginOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="name_wrapper">
        <div class="label_title">登录时间区间</div>
        <el-date-picker
          v-model="timeInterval"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="margin-right: 20px"
        @click="getLoginInfo"
        >查询</el-button
      >
      <el-button
        type="primary"
        style="margin-right: 20px"
        icon="el-icon-refresh-right"
        @click="resetClick"
        >重置</el-button
      >
      <el-button
        type="danger"
        style="margin-right: 20px"
        icon="el-icon-delete"
        @click="batchDeleteClick"
        >批量删除</el-button
      >
      <el-button type="danger" icon="el-icon-delete" @click="clearAllClick"
        >清空</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table
        :data="tableData"
        border
        height="90%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column type="index" width="50" label="序号">
        </el-table-column> -->
        <el-table-column prop="infoId" label="访问ID" width="180">
        </el-table-column>
        <el-table-column prop="username" label="用户名称" width="180">
        </el-table-column>
        <el-table-column prop="phoneNumber" label="用户手机号">
        </el-table-column>
        <el-table-column prop="ipaddr" label="登录IP地址"> </el-table-column>
        <el-table-column prop="loginLocation" label="登录地点">
        </el-table-column>
        <el-table-column prop="browser" label="浏览器类型"> </el-table-column>
        <el-table-column prop="os" label="操作系统"> </el-table-column>
        <el-table-column label="登录状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0">成功</el-tag>
            <el-tag type="danger" v-if="scope.row.status == 1">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="message" label="提示消息"> </el-table-column>
        <el-table-column prop="loginTime" label="访问时间"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <el-button type="text" @click="deleteByIdClick(scope.row.infoId)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
    </div>
  </div>
</template>

<script>
import {
  selectLoginLogInfo,
  cleanAllLoginInfo,
  batchDeleteLog,
} from "@/api/loginLog";
import paginationView from "@/components/PaginationView.vue";
export default {
  data() {
    return {
      loginLogParams: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phoneNumber: "",
        ipaddr: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      timeInterval: "",
      loginOptions: [
        {
          value: 0,
          label: "成功",
        },
        {
          value: 1,
          label: "失败",
        },
      ],
      tableData: [],
      multipleSelection: [],
    };
  },
  components: {
    paginationView,
  },
  mounted() {
    this.getLoginInfo();
  },
  methods: {
    changePaginationSize(val) {
      this.loginLogParams.pageSize = val;
      this.getLoginInfo();
    },
    changePaginationNum(val) {
      this.loginLogParams.pageNum = val;
      this.getLoginInfo();
    },
    async getLoginInfo() {
      if (this.timeInterval) {
        this.loginLogParams.startTime = this.$moment(
          this.timeInterval[0]
        ).format("YYYY-MM-DD 00:00:00");
        this.loginLogParams.endTime = this.$moment(this.timeInterval[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      await selectLoginLogInfo(this.loginLogParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    resetClick() {
      this.loginLogParams = {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phoneNumber: "",
        ipaddr: "",
        status: "",
        startTime: "",
        endTime: "",
      };
      this.timeInterval = "";
      this.getLoginInfo();
    },
    handleSelectionChange(val) {
      //console.log(val);
      this.multipleSelection = val;
    },
    clearAllClick() {
      this.$confirm("是否清空日志?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await cleanAllLoginInfo().then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getLoginInfo();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async deleteInfo(array) {
      await batchDeleteLog(array).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message({ type: "success", message: "删除成功" });
          this.getLoginInfo();
        }
      });
    },
    async batchDeleteClick() {
      this.$confirm("是否删除日志?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let array = [];
          this.multipleSelection.forEach((item) => {
            //console.log(item);
            array.push(item.infoId);
          });
          this.deleteInfo(array);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteByIdClick(id) {
      this.$confirm("是否删除日志?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let array = [];
          array.push(id);
          this.deleteInfo(array);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.login-log-wrapper {
  width: 100%;
  height: 100%;
}

.top_search_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.name_wrapper {
  margin-right: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap; /* 防止换行 */
}

.label_title {
  margin-right: 10px;
}

.table-data-wrapper {
  width: 100%;
  margin-top: 20px;
  height: 95%;
}
</style>