import { request } from "@/requests";

export const selectItemPerson = (data) => {
    return request({
        url: '/annotation/itemUserRole/selectItemUserRole',
        method: 'post',
        data
    })
}

export const selectAllItemUserRole = (data) => {
    return request({
        url: '/annotation/itemUserRole/selectAllItemUserRole/' + data,
        method: 'get'
    })
}

export const addItemUserRole = (data) => {
    return request({
        url: '/annotation/itemUserRole/addItemUserRole',
        method: 'post',
        data
    })
}

export const updateRoleByItemIdAndUserId = (data) => {
    return request({
        url: '/annotation/itemUserRole/updateRoleByItemIdAndUserId',
        method: 'post',
        data
    })
}

export const deleteItemUserRole = (data) => {
    return request({
        url: '/annotation/itemUserRole/deleteItemUserRole',
        method: 'post',
        data
    })
}

// 转移管理员身份
export const transferAdmin = (userId,itemId) => {
    return request({
        url: '/annotation/itemUserRole/transferAdmin/' + userId + "/" + itemId,
        method: 'get',
    })
}