<template>
  <div class="user-manage-wrapper">
    <div class="top_search_wrapper">
      <div class="name_wrapper">
        <div class="label_title">项目名称</div>
        <el-input
          v-model="annotationParam.itemName"
          placeholder="请输入项目名称"
        ></el-input>
      </div>
      <!-- <div class="name_wrapper">
        <div class="label_title">任务类型</div>
        <el-select v-model="annotationParam.taskType" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="name_wrapper">
        <div class="label_title">筛选时间区间</div>
        <el-date-picker
          v-model="timeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="margin-right: 20px"
        @click="searchAnnotate"
        >查询</el-button
      >
      <el-button type="primary" icon="el-icon-refresh-right" @click="resetClick"
        >重置</el-button
      >
    </div>
    <div class="add-btn-wrapper">
      <el-button type="primary" 
         v-if="userInfo.roleId == 1" icon="el-icon-plus" @click="addClick"
        >新建</el-button
      >
      <el-button
        type="danger"
        style="margin-left: 20px"
        icon="el-icon-delete"
        @click="batchDeleteClick"
        v-if="isManagerOrAuditorByProject"
        >批量删除</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table
        :data="tableData"
        border
        height="95%"
        style="width: 100%"
        @row-click="roeClickEvent"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="itemName" label="项目名称" width="180">
        </el-table-column>
        <el-table-column prop="annotationType" label="标注类型" width="180">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.annotationType == 1">序列分类</el-tag>
            <el-tag v-if="scope.row.annotationType == 2">序列标注</el-tag>
            <el-tag v-if="scope.row.annotationType == 3">序列到序列</el-tag>
            <el-tag v-if="scope.row.annotationType == 4">关系分类</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="taskType" label="任务类型" width="180">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.taskType == 1">单标签</el-tag>
            <el-tag v-if="scope.row.taskType == 2">多标签</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="description" label="项目描述" width="180">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <el-button
                type="text"
                style="margin-right: 20px"
                @click.stop="viewClick(scope.row.itemId)"
                ><i class="el-icon-view"></i>查看</el-button
              >
              <el-button
                type="text"
                style="margin-right: 20px"
                v-if="isManagerOrAuditorByProject"
                @click.stop="editClick(scope.row.itemId)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                type="text"
                style="margin-right: 20px"
                v-if="isManagerOrAuditorByProject"
                @click.stop="deleteClick(scope.row.itemId)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
      <dialogView
        :flag="flag"
        :annotatedItemId="annotatedItemId"
        :annotationType="annotationType"
        :showDialogVisible="showDialogVisible"
        @closeDialogView="closeDialogView"
      ></dialogView>
    </div>
  </div>
</template>

<script>
import {
  selectAllAnnotatedItem,
  deleteAnnotatedItemById,
} from "@/api/annotateProjectManagement";
import { getMyInfo } from "@/api/user";
import paginationView from "@/components/PaginationView.vue";
import dialogView from "@/components/DialogView.vue";
export default {
  data() {
    return {
      showDialogVisible: false,
      timeRange: "",
      tableData: [],
      flag: 0, //1-新增  2-查看   3-编辑
      annotationParam: {
        pageNum: 1,
        pageSize: 10,
        itemName: "",
        annotationType: "",
        startTime: "",
        endTime: "",
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      userInfo: {},
      isManagerOrAuditorByProject:false,
      statusOptions: [
        {
          label: "单分类",
          value: 1,
        },
        {
          label: "多分类",
          value: 2,
        },
      ],
      annotatedItemId: null,
    };
  },
  components: {
    paginationView,
    dialogView,
  },
  props: {
    annotationType: {
      Type: Number,
      default: 0,
    },
  },
  mounted() {
    this.getUserInfo();
    this.getAnnotatePage();
  },
  methods: {
    async getUserInfo() {
      this.isManagerOrAuditorByProject = JSON.parse(localStorage.getItem("isManagerOrAuditorByProject"));
      await getMyInfo().then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.userInfo = res.data;
        }
      });
    },
    changePaginationSize(val) {
      this.annotationParam.pageSize = val;
      this.getAnnotatePage();
    },
    changePaginationNum(val) {
      this.annotationParam.pageNum = val;
      this.getAnnotatePage();
    },
    async getAnnotatePage() {
      if (this.timeRange) {
        this.annotationParam.startTime = this.$moment(this.timeRange[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        this.annotationParam.endTime = this.$moment(this.timeRange[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      this.annotationParam.annotationType = this.annotationType;
      await selectAllAnnotatedItem(this.annotationParam).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    addClick() {
      this.flag = 1;
      this.showDialogVisible = true;
    },
    viewClick(id) {
      this.flag = 2;
      this.annotatedItemId = id;
      this.showDialogVisible = true;
    },
    editClick(id) {
      this.flag = 3;
      this.annotatedItemId = id;
      this.showDialogVisible = true;
    },
    deleteClick(id) {
      let array = [];
      array.push(id);
      this.deleteProject(array);
    },
    deleteProject(val) {
      this.$confirm("是否删除此项目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteAnnotatedItemById(val).then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAnnotatePage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    closeDialogView() {
      this.showDialogVisible = false;
      this.getAnnotatePage();
    },
    annotationClick() {
      this.$router.push("/sequenceClassificationAnnotation");
    },
    roeClickEvent(row) {
      //console.log(row);
      this.$router.push({
        path: "/projectDetail",
        query: {
          id: row.itemId,
          isShare: row.isShare,
        },
      });
    },
    batchDeleteClick() {
      let array = [];
      this.mulitSelectList.forEach((item) => {
        //console.log(item);
        array.push(item.itemId);
      });
      this.deleteProject(array);
    },
    handleSelectionChange(value) {
      this.mulitSelectList = value;
    },
    resetClick() {
      this.annotationParam = {
        pageNum: 1,
        pageSize: 10,
        itemName: "",
        annotationType: 1,
        startTime: "",
        endTime: "",
      };
      this.getAnnotatePage();
    },
    searchAnnotate() {
      this.getAnnotatePage();
    },
  },
};
</script>

<style scoped>
.user-manage-wrapper {
  width: 100%;
  height: calc(100% - 20px);
}

.top_search_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.name_wrapper {
  margin-right: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap; /* 防止换行 */
}

.label_title {
  margin-right: 10px;
}

.add-btn-wrapper {
  margin-top: 20px;
}

.table-data-wrapper {
  width: 100%;
  margin-top: 30px;
  height: 86%;
}

.el-icon-view,
.el-icon-edit,
.el-icon-delete,
.el-icon-edit-outline {
  margin-right: 8px;
}
</style>