<template>
  <div class="data-set-wrapper">
    <div class="query-operation-wrapper">
      <div class="queryCriteria-wrapper">
        <div class="label-title">标注文本</div>
        <el-input
          v-model="dataSetParams.text"
          placeholder="请输入标注文本"
        ></el-input>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">标签</div>
        <el-select v-model="dataSetParams.itemLabelId" placeholder="请选择">
          <el-option
            v-for="item in labelOptions"
            :key="item.itemLabelId"
            :label="item.labelName"
            :value="item.itemLabelId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">审核人</div>
        <el-select v-model="dataSetParams.auditor" placeholder="请选择">
          <el-option
            v-for="item in auditOption"
            :key="item.userId"
            :label="item.username"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">审核状态</div>
        <el-select v-model="dataSetParams.isAudit" placeholder="请选择">
          <el-option
            v-for="item in auditStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">审核时间范围</div>
        <el-date-picker
          v-model="auditTimeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @input="handleClearAuditTime"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="query-operation-wrapper">
      <div
        class="queryCriteria-wrapper"
        v-if="
          isManagerOrAuditorByProject == 1 || isManagerOrAuditorByProject == 3
        "
      >
        <div class="label-title">标注人</div>
        <el-select
          v-model="dataSetParams.labeler"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in annotationOption"
            :key="item.userId"
            :label="item.username"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">标注状态</div>
        <el-select v-model="dataSetParams.isLabel" placeholder="请选择">
          <el-option
            v-for="item in annotationStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">标注时间范围</div>
        <el-date-picker
          v-model="annotationTimeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @input="handleClearAnnotationTime"
        >
        </el-date-picker>
      </div>
      <div class="query-button-wrapper">
        <el-button type="primary" @click="queryClick">查询</el-button>
        <el-button type="primary" style="margin-left: 30px" @click="resetClick"
          >重置</el-button
        >
      </div>
    </div>
    <div class="operation-button-wrapper">
      <el-upload
        ref="upload"
        class="upload-demo"
        :action="uplaodAction"
        :headers="headers"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess"
        :data="fileParams"
        :show-file-list="false"
        v-if="isManagerOrAuditorByProject == 1"
      >
        <el-button type="primary">导入</el-button>
      </el-upload>
      <el-button
        type="primary"
        v-if="isManagerOrAuditorByProject == 1"
        style="margin-left: 30px"
        @click="exportClick"
        >导出</el-button
      >
      <el-button
        type="success"
        style="margin-left: 30px"
        @click="annotationClick"
        v-if="
          isManagerOrAuditorByProject == 1 || isManagerOrAuditorByProject == 2
        "
        >开始标注</el-button
      >
      <el-button
        type="success"
        style="margin-left: 30px"
        @click="examineClick"
        v-if="
          isManagerOrAuditorByProject == 1 || isManagerOrAuditorByProject == 3
        "
        >开始审核</el-button
      >
      <el-button
        type="warning"
        style="margin-left: 30px"
        @click="allocationDatasetClick"
        v-if="isManagerOrAuditorByProject == 1"
        >自定义分配数据集</el-button
      >
      <el-button
        slot="reference"
        v-if="isManagerOrAuditorByProject == 1"
        type="warning"
        @click="averageDatasetClick"
        style="margin-left: 30px"
        >均分数据集</el-button
      >
      <el-button
        type="danger"
        style="margin-left: 30px"
        @click="batchDeleteClick"
        v-if="isManagerOrAuditorByProject == 1"
        >批量删除</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table
        :data="tableData"
        border
        height="80%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          width="50"
          :index="indexMethod"
          label="序号"
        >
        </el-table-column>
        <el-table-column
          prop="text"
          label="标注文本"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="annotationType" label="标注类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.annotationType == 1">序列分类</el-tag>
            <el-tag v-if="scope.row.annotationType == 2">序列标注</el-tag>
            <el-tag v-if="scope.row.annotationType == 3">序列到序列</el-tag>
            <el-tag v-if="scope.row.annotationType == 4">关系分类</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="标签" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.sequencedPacketExchangeVoList.length > 0">
              <el-tag
                v-for="(item, index) in filteredLabelList(
                  scope.row.sequencedPacketExchangeVoList
                )"
                :key="index"
                :type="item.deleted == 1 ? 'danger' : ''"
                style="margin-right: 10px"
                >{{ item.labelName }}</el-tag
              >
            </div>
            <div
              v-if="
                scope.row.sequencedPacketExchangeVoList.length <= 0 &&
                scope.row.isAudit == 1
              "
            >
              <el-tag type="danger">审核不通过</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isLabel" label="标注状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isLabel == 0">未标注</el-tag>
            <el-tag v-if="scope.row.isLabel == 1" type="success">已标注</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="被分配人" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.labeler }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否一致">
          <template slot-scope="scope">
            <span v-if="scope.row.isConsistent">是</span>
            <span v-else style="color: red">否</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="labelTime" label="标注时间"> </el-table-column> -->
        <el-table-column prop="isAudit" label="审核状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isAudit == 0">未审核</el-tag>
            <el-tag v-if="scope.row.isAudit == 1" type="success">已审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="auditorName" label="审核人"> </el-table-column>
        <el-table-column prop="auditTime" label="审核时间"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="400">
          <template slot-scope="scope">
            <div class="custom-cell">
              <el-button
                type="text"
                style="margin-right: 10px"
                @click.stop="viewUserClick(scope.row)"
                ><i class="el-icon-view"></i>查看</el-button
              >
              <el-button
                type="text"
                style="margin-right: 10px"
                v-if="isManagerOrAuditorByProject == 1"
                @click.stop="editUserClick(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                type="text"
                style="margin-right: 10px"
                v-if="
                  scope.row.isAudit == 0 && isManagerOrAuditorByProject != 3
                "
                @click.stop="demisionClick(scope.row)"
                ><i class="el-icon-s-management"></i>标注</el-button
              >
              <el-button
                type="text"
                style="margin-right: 10px"
                v-if="
                  scope.row.isAudit == 0 &&
                  scope.row.isLabel == 1 &&
                  (isManagerOrAuditorByProject == 1 ||
                    isManagerOrAuditorByProject == 3)
                "
                @click.stop="handleExamineClick(scope.row)"
                ><i class="el-icon-user"></i>审核</el-button
              >
              <el-button
                type="text"
                v-if="isManagerOrAuditorByProject == 1"
                @click.stop="deleteDataSetClick(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
      <dataSetDialog
        :dataSetDialogVisible="dataSetDialogVisible"
        :textId="textId"
        :flag="flag"
        @closedDataSetDialog="closedDataSetDialog"
      ></dataSetDialog>
      <el-dialog
        title="分配数据集"
        :visible.sync="allocationDatasetDialogVisible"
        @closed="allocationDatasetDialogClosedHandle"
        width="30%"
      >
        <div>
          <span>请选择被分配人：</span>
          <el-select
            v-model="allocationPersionList"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in allocationDatasetOptions"
              :key="item.userId"
              :label="item.username"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-top: 20px">
          <span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;分配方式：</span
          >
          <el-radio-group v-model="radioValue">
            <el-radio :label="true">覆盖（替换之前的分配）</el-radio>
            <el-radio :label="false">添加</el-radio>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="allocationDatasetDialogVisibleCancleClick"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="allocationDatasetDialogVisibleSureClick"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { uploadURL } from "@/requests";
import paginationView from "@/components/PaginationView.vue";
import { getFileType } from "@/utils/util";
import {
  selectSequencedPacketExchangeAnnotatedTextByCondition,
  selectRangeDataSet,
  averageDataSet,
} from "@/api/dataSet";
import { selectAllItemUserRole } from "@/api/projectPerson";
import { selectAllItemLabelByItemId } from "@/api/projectLabel";
import { deleteAnnotatedText } from "@/api/dataSet";
import dataSetDialog from "@/components/DataSetDialog.vue";
import { isManagerOrAuditor } from "@/api/projectRole";

export default {
  data() {
    return {
      annotationStatusOptions: [
        {
          value: 0,
          label: "未标注",
        },
        {
          value: 1,
          label: "已标注",
        },
      ],
      auditStatusOptions: [
        {
          value: 0,
          label: "未审核",
        },
        {
          value: 1,
          label: "已审核",
        },
      ],
      flag: 0,
      dataSetDialogVisible: false,
      multipleSelectionDataSet: [],
      auditOption: [],
      annotationOption: [],
      labelOptions: [],
      tableData: [],
      allocationDatasetOptions: [],
      allocationPersionList: [],
      uplaodAction: "",
      headers: {
        Authentication: "",
      },
      allocationDatasetDialogVisible: false,
      fileParams: {
        itemId: 0,
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      auditTimeRange: "",
      annotationTimeRange: "",
      dataSetParams: {
        itemLabelId: "",
        pageNum: 1,
        pageSize: 10,
        itemId: 0,
        text: "",
        isLabel: "",
        labeler: "",
        labelStartTime: "",
        labelEndTime: "",
        isShare: 0,
        isAudit: "",
        auditor: "",
        auditStartTime: "",
        auditEndTime: "",
        createStartTime: "",
        createEndTime: "",
      },
      radioValue: null,
      isShareData: false,
      projectId: 0,
      textId: 0,
      projectIsShare: 0,
      isManagerOrAuditorByProject: null,
    };
  },
  components: {
    paginationView,
    dataSetDialog,
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.projectId = Number(query.id);
        this.projectIsShare = Number(query.isShare);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.init();
    this.getDataSetPage();
    this.getProjectPerson();
    this.getProjectLabel();
  },
  methods: {
    handleClearAuditTime(value) {
      if (!value) {
        //console.log("111");
        this.auditTimeRange = "";
        this.dataSetParams.auditStartTime = "";
        this.dataSetParams.auditEndTime = "";
      }
    },
    handleClearAnnotationTime(value) {
      if (!value) {
        //console.log("111");
        this.annotationTimeRange = "";
        this.dataSetParams.createStartTime = "";
        this.dataSetParams.createEndTime = "";
      }
    },
    async init() {
      await isManagerOrAuditor(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.isManagerOrAuditorByProject = res.data;
        }
      });
    },
    indexMethod(index) {
      index =
        index +
        1 +
        (this.paginationData.currentPage - 1) * this.paginationData.pageSize;
      return index;
    },
    changePaginationSize(val) {
      this.dataSetParams.pageSize = val;
      this.getDataSetPage();
    },
    changePaginationNum(val) {
      this.dataSetParams.pageNum = val;
      this.getDataSetPage();
    },
    filteredLabelList(labelList) {
      //console.log(
      //   labelList.map((item) => {
      //     return item.labelName;
      //   })
      // );
      return labelList.map((item) => {
        return item;
      });
    },
    annotationClick() {
      this.$router.push({
        path: "/sequenceClassificationAnnotation",
        query: {
          id: this.projectId,
          isShare: this.projectIsShare,
        },
      });
    },
    examineClick() {
      this.$router.push({
        path: "/examine",
        query: {
          id: this.projectId,
          isShare: this.projectIsShare,
        },
      });
    },
    allocationDatasetClick() {
      this.isShareData = false;
      this.allocationDatasetDialogVisible = true;
    },
    allocationDatasetDialogVisibleCancleClick() {
      this.allocationDatasetDialogVisible = false;
    },
    allocationDatasetDialogClosedHandle() {
      this.radioValue = null;
      this.allocationPersionList = [];
    },
    allocationDatasetDialogVisibleSureClick() {
      if (!this.isShareData) {
        this.allcationDataMethod();
      } else {
        this.shareDataset();
      }
    },
    async shareDataset() {
      if (this.radioValue == null) {
        this.$message.error("请选择分配方式");
        return;
      }
      await averageDataSet({
        itemId: this.projectId,
        annotatorIds: this.allocationPersionList,
        logic: this.radioValue,
      }).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message.success("分配成功");
          this.allocationDatasetDialogVisible = false;
          this.getDataSetPage();
        }
      });
    },
    demisionClick(row) {
      //console.log(row);
      this.$router.push({
        path: "/sequenceClassificationAnnotation",
        query: {
          id: this.projectId,
          isShare: this.projectIsShare,
          textId: row.textId,
        },
      });
    },
    handleExamineClick(row) {
      //console.log(row);
      this.$router.push({
        path: "/examine",
        query: {
          id: this.projectId,
          isShare: this.projectIsShare,
          textId: row.textId,
        },
      });
    },
    beforeUpload(file) {
      //console.log(file);
      this.fileParams.itemId = this.projectId;
      this.headers.Authentication = "Bearer " + localStorage.getItem("token");
      let type = getFileType(file.name);
      if (type == "excel") {
        return new Promise((resolve) => {
          this.$nextTick(() => {
            this.uplaodAction =
              uploadURL +
              "/annotation/annotatedText/importAnnotatedTextByExcel";
            resolve();
          });
        });
      } else {
        return new Promise((resolve) => {
          this.$nextTick(() => {
            this.uplaodAction =
              uploadURL +
              "/annotation/annotatedText/importAnnotatedTextByJsonl";
            resolve();
          });
        });
      }
    },
    uploadSuccess(response) {
      //console.log(response);
      if (response.code == 200) {
        this.$message.success("导入成功");
      }
      this.getDataSetPage();
    },
    async getDataSetPage() {
      if (this.auditTimeRange) {
        this.dataSetParams.auditStartTime = this.$moment(
          this.auditTimeRange[0]
        ).format("YYYY-MM-DD 00:00:00");
        this.dataSetParams.auditEndTime = this.$moment(
          this.auditTimeRange[1]
        ).format("YYYY-MM-DD 23:59:59");
      }
      if (this.annotationTimeRange) {
        this.dataSetParams.labelStartTime = this.$moment(
          this.annotationTimeRange[0]
        ).format("YYYY-MM-DD 00:00:00");
        this.dataSetParams.labelEndTime = this.$moment(
          this.annotationTimeRange[1]
        ).format("YYYY-MM-DD 23:59:59");
      }
      this.dataSetParams.itemId = this.projectId;
      this.dataSetParams.isShare = this.projectIsShare;
      await selectSequencedPacketExchangeAnnotatedTextByCondition(
        this.dataSetParams
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    queryClick() {
      this.getDataSetPage();
    },
    resetClick() {
      (this.dataSetParams = {
        itemLabelId: "",
        pageNum: 1,
        pageSize: 10,
        text: "",
        isLabel: "",
        labeler: "",
        labelStartTime: "",
        labelEndTime: "",
        isShare: 0,
        isAudit: "",
        auditor: "",
        auditStartTime: "",
        auditEndTime: "",
        createStartTime: "",
        createEndTime: "",
      }),
        (this.auditTimeRange = "");
      this.annotationTimeRange = "";
      this.getDataSetPage();
    },
    // 自定义分配数据集
    async allcationDataMethod() {
      if (this.radioValue == null) {
        this.$message.error("请选择分配方式");
        return;
      }
      let query = {
        itemId: 0,
        textList: [],
        labelList: [],
        logic: true,
      };
      query.itemId = this.projectId;
      query.labelList = this.allocationPersionList;
      query.logic = this.radioValue;
      this.multipleSelectionDataSet.forEach((item) => {
        query.textList.push(item.textId);
      });
      await selectRangeDataSet(query).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.allocationDatasetDialogVisible = false;
          this.getDataSetPage();
        }
      });
    },
    async averageDatasetClick() {
      this.isShareData = true;
      this.allocationDatasetDialogVisible = true;
    },
    async getProjectPerson() {
      this.auditOption = [];
      this.annotationOption = [];
      this.allocationDatasetOptions = [];
      await selectAllItemUserRole(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          res.data.forEach((item) => {
            if (item.itemRoleId == 1 || item.itemRoleId == 3) {
              this.auditOption.push(item);
            }
            this.allocationDatasetOptions.push(item);
            this.annotationOption.push(item);
          });
        }
      });
    },
    async getProjectLabel() {
      this.labelOptions = [];
      await selectAllItemLabelByItemId(this.projectId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.labelOptions = res.data;
        }
      });
    },
    handleSelectionChange(val) {
      //console.log(val);
      this.multipleSelectionDataSet = val;
    },
    batchDeleteClick() {
      this.$confirm("是否删除数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let array = [];
          this.multipleSelectionDataSet.forEach((item) => {
            //console.log(item);
            array.push(item.textId);
          });
          this.deleteDataSet(array);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async deleteDataSet(array) {
      let query = {
        itemId: this.projectId,
        ids: array,
      };
      await deleteAnnotatedText(query).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.getDataSetPage();
        }
      });
    },
    deleteDataSetClick(row) {
      //console.log(row);
      this.multipleSelectionDataSet = [];
      this.multipleSelectionDataSet.push(row);
      this.batchDeleteClick();
    },
    viewUserClick(row) {
      this.dataSetDialogVisible = true;
      this.flag = 2;
      this.textId = row.textId;
    },
    editUserClick(row) {
      this.dataSetDialogVisible = true;
      this.flag = 1;
      this.textId = row.textId;
    },
    closedDataSetDialog() {
      this.dataSetDialogVisible = false;
      this.getDataSetPage();
    },
    async exportClick() {
      let fileUrl =
        uploadURL +
        "/annotation/annotatedText/exportAnnotatedTextByCondition/" +
        this.projectId +
        "/" +
        localStorage.getItem("userId");
      try {
        const link = document.createElement("a");
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("下载失败：", error);
      }
    },
  },
};
</script>

<style scoped>
.data-set-wrapper {
  width: 100%;
  height: 100%;
}

.query-operation-wrapper {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.queryCriteria-wrapper {
  display: flex;
  margin-right: 30px;
  align-items: center;
}
.label-title {
  white-space: nowrap; /* 防止换行 */
  margin-right: 10px;
}

.operation-button-wrapper {
  margin: 20px 0;
  display: flex;
}

.table-data-wrapper {
  height: 80%;
  overflow-y: scroll;
}
</style>