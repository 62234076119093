import { request } from "@/requests";

export const selectItemLabel = (data) => {
    return request({
        url: '/annotation/itemLabel/selectItemLabel',
        method: 'post',
        data
    })
}

export const addItemLabel = (data) => {
    return request({
        url: '/annotation/itemLabel/addItemLabel',
        method: 'post',
        data
    })
}

export const updateItemLabel = (data) => {
    return request({
        url: '/annotation/itemLabel/updateItemLabel',
        method: 'post',
        data
    })
}

export const deleteItemLabel = (data) => {
    return request({
        url: '/annotation/itemLabel/deleteItemLabel',
        method: 'post',
        data
    })
}

export const selectAllItemLabelByItemId = (data) => {
    return request({
        url: '/annotation/itemLabel/selectAllItemLabelByItemId/' + data,
        method: 'get'
    })
}
