import { request } from "@/requests";

export const loginByPhone = (data) => {
    return request({
        url: '/auth/smsLogin',
        method: 'post',
        data:data
    })
}

export const getPhoneCode = (data) => {
    return request({
        url: '/auth/sendSms',
        method: 'post',
        data:data
    })
}

export const loginByPassword = (data) => {
    return request({
        url: '/auth/login',
        method: 'post',
        data:data
    })
}

export const logout = (data) => {
    return request({
        url: '/auth/logout',
        method: 'get',
    })
}