<template>
  <div class="login-log-wrapper">
    <div class="top_search_wrapper">
      <div class="name_wrapper">
        <div class="label_title">操作模块</div>
        <el-input
          v-model="operationLogParams.title"
          placeholder="请输入操作模块"
        ></el-input>
      </div>
      <div class="name_wrapper">
        <div class="label_title">操作人员</div>
        <el-input
          v-model="operationLogParams.operName"
          placeholder="请输入操作人员"
        ></el-input>
      </div>
      <div class="name_wrapper">
        <div class="label_title">操作类型</div>
        <el-select
          v-model="operationLogParams.businessType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in operationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="name_wrapper">
        <div class="label_title">操作状态</div>
        <el-select v-model="operationLogParams.status" placeholder="请选择">
          <el-option
            v-for="item in operationStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="name_wrapper">
        <div class="label_title">操作时间区间</div>
        <el-date-picker
          v-model="timeInterval"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="margin-right: 20px"
        @click="getOperationInfo"
        >查询</el-button
      >
      <el-button
        type="primary"
        style="margin-right: 20px"
        icon="el-icon-refresh-right"
        @click="resetClick"
        >重置</el-button
      >
    </div>
    <div class="clear-btn-wrapper">
      <el-button
        type="danger"
        style="margin-right: 20px"
        icon="el-icon-delete"
        @click="batchDeleteClick"
        >批量删除</el-button
      >
      <el-button type="danger" icon="el-icon-delete" @click="clearAllClick"
        >清空</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table
        :data="tableData"
        border
        height="90%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column type="index" width="50" label="序号">
        </el-table-column> -->
        <el-table-column prop="operId" label="日志ID" width="180">
        </el-table-column>
        <el-table-column prop="title" label="模块标题" width="180">
        </el-table-column>
        <el-table-column prop="phoneNumber" label="业务类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.businessType == 0">其他</el-tag>
            <el-tag v-if="scope.row.businessType == 1">新增</el-tag>
            <el-tag v-if="scope.row.businessType == 2">修改</el-tag>
            <el-tag v-if="scope.row.businessType == 3">删除</el-tag>
            <el-tag v-if="scope.row.businessType == 4">授权</el-tag>
            <el-tag v-if="scope.row.businessType == 5">导出</el-tag>
            <el-tag v-if="scope.row.businessType == 6">导入</el-tag>
            <el-tag v-if="scope.row.businessType == 7">强退</el-tag>
            <el-tag v-if="scope.row.businessType == 8">生成代码</el-tag>
            <el-tag v-if="scope.row.businessType == 9">清空数据</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operName" label="操作人员"> </el-table-column>
        <el-table-column prop="operIp" label="主机地址"> </el-table-column>
        <el-table-column prop="operLocation" label="操作地点">
        </el-table-column>
        <el-table-column label="操作状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0">正常</el-tag>
            <el-tag type="danger" v-if="scope.row.status == 1">异常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operTime" label="操作时间"> </el-table-column>
        <el-table-column prop="costTime" label="消耗时间"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <el-button type="text" @click="viewClick(scope.row.operId)"
                ><i class="el-icon-view"></i>查看</el-button
              >
              <el-button type="text" @click="deleteByIdClick(scope.row.operId)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
    </div>
    <operationLogDialog @closeDialogView="closeDialogView" :operationLogId="operationLogId" :dialogVisible="dialogVisible"
></operationLogDialog>
  </div>
</template>

<script>
import {
  selectOperationLogInfo,
  cleanAllOperationInfo,
  batchDeleteLog,
} from "@/api/operationLog";
import paginationView from "@/components/PaginationView.vue";
import operationLogDialog from "@/components/OperationDialog.vue";
export default {
  data() {
    return {
      dialogVisible:false,
      operationLogId:0,
      operationLogParams: {
        pageNum: 1,
        pageSize: 10,
        operIp: "",
        title: "",
        operName: "",
        businessType: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      timeInterval: "",
      operationOptions: [
        {
          value: 1,
          label: "新增",
        },
        {
          value: 2,
          label: "修改",
        },
        {
          value: 3,
          label: "删除",
        },
        {
          value: 4,
          label: "授权",
        },
        {
          value: 5,
          label: "导出",
        },
        {
          value: 6,
          label: "导入",
        },
        {
          value: 7,
          label: "强退",
        },
        {
          value: 8,
          label: "生成代码",
        },
        {
          value: 9,
          label: "清空数据",
        },
        {
          value: 0,
          label: "其他",
        },
      ],
      operationStatus: [
        {
          value: 0,
          label: "正常",
        },
        {
          value: 1,
          label: "异常",
        },
      ],
      tableData: [],
      multipleSelection: [],
    };
  },
  components: {
    paginationView,
    operationLogDialog
  },
  mounted() {
    this.getOperationInfo();
  },
  methods: {
    changePaginationSize(val) {
      this.operationLogParams.pageSize = val;
      this.getOperationInfo();
    },
    changePaginationNum(val) {
      this.operationLogParams.pageNum = val;
      this.getOperationInfo();
    },
    async getOperationInfo() {
      if (this.timeInterval) {
        this.operationLogParams.startTime = this.$moment(
          this.timeInterval[0]
        ).format("YYYY-MM-DD 00:00:00");
        this.operationLogParams.endTime = this.$moment(
          this.timeInterval[1]
        ).format("YYYY-MM-DD 23:59:59");
      }
      await selectOperationLogInfo(this.operationLogParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    resetClick() {
      this.operationLogParams = {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phoneNumber: "",
        ipaddr: "",
        status: "",
        startTime: "",
        endTime: "",
      };
      this.timeInterval = "";
      this.getOperationInfo();
    },
    handleSelectionChange(val) {
      //console.log(val);
      this.multipleSelection = val;
    },
    clearAllClick() {
      this.$confirm("是否清空日志?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await cleanAllOperationInfo().then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getOperationInfo();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async deleteOperationInfo(array) {
      await batchDeleteLog(array).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message({ type: "success", message: "删除成功" });
          this.getOperationInfo();
        }
      });
    },
    async batchDeleteClick() {
      this.$confirm("是否删除日志?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let array = [];
          this.multipleSelection.forEach((item) => {
            //console.log(item);
            array.push(item.operId);
          });
          this.deleteOperationInfo(array);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteByIdClick(id) {
      this.$confirm("是否删除日志?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let array = [];
          array.push(id);
          this.deleteOperationInfo(array);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    closeDialogView(){
      this.dialogVisible = false;
      this.getOperationInfo()
    },
    viewClick(id){
      this.dialogVisible = true;
      this.operationLogId = id
    }
  },
};
</script>

<style scoped>
.login-log-wrapper {
  width: 100%;
  height: 100%;
}

.top_search_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.name_wrapper {
  margin-right: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap; /* 防止换行 */
}

.label_title {
  margin-right: 10px;
}

.clear-btn-wrapper {
  margin-top: 20px;
  display: flex;
}

.table-data-wrapper {
  width: 100%;
  margin-top: 20px;
  height: 89%;
}
</style>