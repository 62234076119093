<template>
  <div class="recycle-bin-wrapper">
    <div class="search-wrapper">
      <div class="label-title">删除时间范围</div>
      <el-date-picker
        v-model="searchTimeValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-left: 30px" @click="queryClick"
        >查询</el-button
      >
      <el-button type="primary" style="margin-left: 30px" @click="resetClick"
        >重置</el-button
      >
    </div>
    <div class="recycle-bin-operation-wrapper">
      <el-button type="primary" style="margin-top: 20px" @click="recoverCilck"
        >批量还原</el-button
      >
    </div>
    <div class="recycle-bin-content-wrapper">
      <div class="recycle-bin-table-wrapper">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            type="index"
            width="50"
            :index="indexMethod"
            label="序号"
          >
          </el-table-column>
          <el-table-column
            prop="text"
            label="标注文本"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="annotationType" label="标注类型">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.annotationType == 1">序列分类</el-tag>
              <el-tag v-if="scope.row.annotationType == 2">序列标注</el-tag>
              <el-tag v-if="scope.row.annotationType == 3">序列到序列</el-tag>
              <el-tag v-if="scope.row.annotationType == 4">关系分类</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="标签" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag
                v-for="(item, index) in filteredLabelList(
                  scope.row.sequencedPacketExchangeVoList
                )"
                :key="index"
                style="margin-right: 10px"
                >{{ item }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="isLabel" label="标注状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isLabel == 0">未标注</el-tag>
              <el-tag v-if="scope.row.isLabel == 1" type="success"
                >已标注</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="标注人" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-for="(item, index) in scope.row.sequencedPacketExchangeVoList"
                :key="index"
                style="margin-right: 10px"
                >{{ item.labelName }}<span>：</span
                ><span style="font-weight: 600">{{
                  item.labelerName
                }}</span></span
              >
            </template>
          </el-table-column>
          <el-table-column label="是否一致">
            <template slot-scope="scope">
              <span v-if="scope.row.isConsistent">是</span>
              <span v-else style="color: red">否</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="labelTime" label="标注时间"> </el-table-column> -->
          <el-table-column prop="isAudit" label="审核状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isAudit == 0">未审核</el-tag>
              <el-tag v-if="scope.row.isAudit == 1">已审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="auditorName" label="审核人"> </el-table-column>
          <el-table-column prop="auditTime" label="审核时间"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <div class="custom-cell">
                <el-button
                  type="text"
                  style="margin-right: 10px"
                  @click="recoverOneClick(scope.row)"
                  ><i class="el-icon-refresh-left" style="margin-right: 4px"></i
                  >恢复</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
    </div>
  </div>
</template>

<script>
import paginationView from "@/components/PaginationView.vue";
import {
  selectAllDeletedAnnotatedText,
  recoverDeletedAnnotatedText,
} from "@/api/dataSet";
export default {
  data() {
    return {
      projectId: 0,
      tableData: [],
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      dataParams: {
        pageNum: 1,
        pageSize: 10,
        itemId: 0,
        startTime: "",
        endTime: "",
      },
      multipleSelectionDataSet: [],
      searchTimeValue: "",
    };
  },
  components: {
    paginationView,
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.projectId = Number(query.id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getDataPage();
  },
  methods: {
    queryClick() {
      if (this.searchTimeValue) {
        this.dataParams.startTime = this.$moment(
          this.searchTimeValue[0]
        ).format("YYYY-MM-DD 00:00:00");
        this.dataParams.endTime = this.$moment(
          this.searchTimeValue[1]
        ).format("YYYY-MM-DD 23:59:59");
      }
      this.getDataPage();
    },
    resetClick() {
      this.dataParams.startTime = ""
      this.dataParams.endTime = ""
      this.searchTimeValue = ""
      this.getDataPage();
    },
    indexMethod(index) {
      index =
        index +
        1 +
        (this.paginationData.currentPage - 1) * this.paginationData.pageSize;
      return index;
    },
    changePaginationSize(val) {
      this.dataParams.pageSize = val;
      this.getDataPage();
    },
    changePaginationNum(val) {
      this.dataParams.pageNum = val;
      this.getDataPage();
    },
    async getDataPage() {
      this.dataParams.itemId = this.projectId;
      await selectAllDeletedAnnotatedText(this.dataParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    filteredLabelList(labelList) {
      return labelList.map((item) => {
        return item.labelName;
      });
    },
    handleSelectionChange(val) {
      //console.log(val);
      this.multipleSelectionDataSet = val;
    },
    async recoverDeleteData() {
      let arr = [];
      this.multipleSelectionDataSet.forEach((item) => {
        arr.push(item.textId);
      });
      await recoverDeletedAnnotatedText(arr).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message.success("恢复成功");
          this.getDataPage();
        }
      });
    },
    recoverCilck() {
      if (this.multipleSelectionDataSet.length == 0) {
        this.$message.error("请选择要恢复的数据");
        return;
      }
      this.recoverDeleteData();
    },
    recoverOneClick(row) {
      this.multipleSelectionDataSet = [];
      this.multipleSelectionDataSet.push(row);
      this.recoverDeleteData();
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  display: flex;
  align-items: center;
}

.label-title {
  margin-right: 10px;
}
.recycle-bin-wrapper {
  width: 100%;
  height: 100%;
}

.recycle-bin-operation-wrapper {
  width: 100%;
  height: 10%;
}

.recycle-bin-content-wrapper {
  width: 100%;
  height: 90%;
}

.recycle-bin-table-wrapper {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
}
</style>