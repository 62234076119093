<template>
  <div class="project-detail-wrapper">
    <div class="back-icon-wrapper">
      <i
        class="el-icon-back"
        style="font-size: 25px; font-weight: bold"
        @click="returnBackClick"
      ></i>
    </div>
    <div class="project-detail-top-nav-wrapper">
      <el-tabs type="border-card" @tab-click="handleTabChange">
        <el-tab-pane label="数据集"
          ><DataSet ref="dataSet"></DataSet
        ></el-tab-pane>
        <el-tab-pane
          label="标签类型管理"
          v-if="isManagerOrAuditorByProject == 1"
          ><LabelTypeManage ref="labelTypeManage"></LabelTypeManage
        ></el-tab-pane>
        <el-tab-pane label="标签管理" v-if="isManagerOrAuditorByProject == 1"
          ><LabelManage ref="labelManage"></LabelManage
        ></el-tab-pane>
        <el-tab-pane label="人员管理" v-if="isManagerOrAuditorByProject == 1"
          ><ProjectPersonManage ref="projectPersonManage"></ProjectPersonManage
        ></el-tab-pane>
        <el-tab-pane
          label="标注数据统计"
          v-if="isManagerOrAuditorByProject == 1"
          ><LabelDataStatistics ref="labelDataStatistics"></LabelDataStatistics
        ></el-tab-pane>
        <el-tab-pane label="回收站" v-if="isManagerOrAuditorByProject == 1"
          ><RecycleBin ref="recycleBin"></RecycleBin
        ></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DataSet from "@/components/DataSet.vue";
import LabelDataStatistics from "@/components/LabelDataStatistics.vue";
import LabelManage from "@/components/LabelManage.vue";
import ProjectPersonManage from "@/components/ProjectPersonManage.vue";
import RecycleBin from "@/components/recycleBin.vue";
import LabelTypeManage from "@/components/LabelTypeManage.vue";
import { isManagerOrAuditor } from "@/api/projectRole";

export default {
  data() {
    return {
      itemId: 0,
      isManagerOrAuditorByProject: null,
    };
  },
  components: {
    DataSet,
    LabelDataStatistics,
    LabelManage,
    ProjectPersonManage,
    RecycleBin,
    LabelTypeManage,
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.itemId = Number(query.id);
        this.getRoleByProject();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getRoleByProject();
  },
  methods: {
    returnBackClick() {
      this.$router.push({
        path: "/sequenceClassification",
      });
    },
    handleTabChange(tab) {
      //console.log(tab);
      // 根据标签页的标签名称选择要调用的组件实例
      switch (tab.label) {
        case "数据集":
          this.$refs.dataSet.init();
          this.$refs.dataSet.getDataSetPage();
          this.$refs.dataSet.getProjectPerson();
          this.$refs.dataSet.getProjectLabel();
          this.$refs.dataSet.resetClick();
          break;
        case "标签管理":
          this.$refs.labelManage.getLabelDataPage();
          this.$refs.labelManage.getLabelType();
          break;
        case "人员管理":
          this.$refs.projectPersonManage.getRoleList();
          this.$refs.projectPersonManage.getProjectPerson();
          break;
        case "标签类型管理":
          this.$refs.labelTypeManage.getLabelTypePage();
          break;
        case "标注数据统计":
          this.$refs.labelDataStatistics.getPersonnelAnnotationDetails();
          this.$refs.labelDataStatistics.getPersonnelAssignment();
          this.$refs.labelDataStatistics.getLabelDistribution();
          this.$refs.labelDataStatistics.getLabelAbnormal();
          this.$refs.labelDataStatistics.getLabelerLabelStatistics();
          // this.$refs.labelDataStatistics.renderStackedBarChart();
          break;
        case "回收站":
          this.$refs.recycleBin.getDataPage();
          break;
      }
    },
    async getRoleByProject() {
      await isManagerOrAuditor(this.itemId).then((res) => {
        //console.log("角色");
        //console.log(res);
        if (res.code == 200) {
          if (res.data == 1)
            localStorage.setItem(
              "isManagerOrAuditorByProject",
              JSON.stringify(res.data)
            );
          this.isManagerOrAuditorByProject = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.project-detail-wrapper {
  width: 100%;
  height: 100%;
}
.project-detail-top-nav-wrapper {
  width: 100%;
  margin-top: 5px;
  height: calc(100% - 30px);
  overflow-y: auto;
}

:deep(.el-tabs--border-card) {
  height: calc(100% - 2px);
}

:deep(.el-tabs__content) {
  height: 92%;
}

:deep(.el-tab-pane) {
  height: 100%;
}
</style>