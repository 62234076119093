<template>
  <div class="sequence-classification-annotation-wrapper">
    <div class="retrieval-wrapper">
      <!-- <div class="retrieval-container">
        <div class="label-title">关键词</div>
        <el-input
          placeholder="请输入关键词"
          style="margin: 0 10px"
          v-model="datasetParams.text"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="margin-left: 30px"
          @click="getData"
          >检索</el-button
        >
      </div> -->
      <div class="back-icon-wrapper">
        <i
          class="el-icon-back"
          style="font-size: 25px; font-weight: bold"
          @click="returnBackClick"
        ></i>
      </div>
      <div v-if="!emptyData" class="residue-wrapper">
        剩余未标注：{{ total }}条
      </div>
    </div>
    <div class="sequence-classification-annotation" v-if="!emptyData">
      <div class="text-content-wrapper">
        <div class="text-conten-title-wrapper">文本内容</div>
        <div class="text-content-detail" v-html="textLabelDetails.text"></div>
      </div>
      <div class="label-list-wrapper">
        <div class="label-general-title-wrapper">标签分类</div>
        <div class="label-wrapper">
          <div
            class="label-select-wrapper"
            v-for="(item, index) in labelList"
            :key="index"
          >
            <div style="margin-bottom: 5px">{{ item.labelTypeName }}</div>
            <el-radio-group v-model="selectedLabel[item.labelTypeName]">
              <el-radio
                :name="item1.labelName"
                :label="item1.itemLabelId"
                border
                v-for="(item1, index) in item.labelTextVoList"
                :key="index"
                >{{ item1.labelName }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="operation-btn-wrapper">
          <!-- <el-button type="primary" @click="saveDimension">保存</el-button> -->
          <el-button type="success" @click="previousClick">上一条</el-button>
          <el-button type="success" @click="nextClick">下一条</el-button>
          <el-button type="primary" @click="resetClick"
            >返回至第一条未标注的数据</el-button
          >
        </div>

        <div
          v-if="
            isManagerOrAuditorByProject == 1 || isManagerOrAuditorByProject == 3
          "
          class="label-general-title-wrapper"
          style="margin-top: 20px"
        >
          标注详情
        </div>
        <div
          v-if="
            isManagerOrAuditorByProject == 1 || isManagerOrAuditorByProject == 3
          "
          class="label-wrapper"
        >
          <el-table
            :data="textLabelDetails.sequencedPacketExchangeByLabelerList"
            border
            style="width: 100%"
          >
            <el-table-column prop="labelerName" label="标注人">
            </el-table-column>
            <el-table-column prop="labelName" label="标签">
              <template slot-scope="scope">
                <span
                  v-for="(item, index) in filteredLabelList(
                    scope.row.sequencedPacketExchangeVoList
                  )"
                  :key="index"
                  style="margin-right: 5px"
                  >{{ item }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-empty
      :image-size="200"
      style="width: 100%; height: 100%"
      v-if="emptyData"
      :description="description"
    ></el-empty>
  </div>
</template>

<script>
import {
  addSequencedPacketExchange,
  selectFirstUnannotatedText,
  selectPreviousAnnotatedText,
  selectNextUnannotatedText,
  selectSequencedPacketExchangeByCondition,
  selectUnannotatedTextCount,
} from "@/api/dimension";
import { selectLabelTypeByType } from "@/api/labelTypeManage";
export default {
  data() {
    return {
      datasetParams: {
        pageNum: 1,
        pageSize: 1,
        itemId: 0,
        isShare: 0,
        text: "",
        isLabel: 0,
      },
      emptyData: false,
      dimensionParams: {
        textId: 0,
        itemId: 0,
        itemLabelIds: [],
      },
      description: "",
      total: 0,
      textLabelDetails: {},
      labelList: [],
      selectedLabel: {},
      userId: 0,
      isManagerOrAuditorByProject: null,
    };
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.datasetParams.itemId = Number(query.id);
        this.datasetParams.isShare = Number(query.isShare);
        if (query.textId) {
          this.getDataById(query.textId);
        } else {
          this.getData();
        }
        this.getUnanotatedTextCount();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    this.getLabelList();
    this.userId = Number(localStorage.getItem("userId"));
    this.isManagerOrAuditorByProject = JSON.parse(
      localStorage.getItem("isManagerOrAuditorByProject")
    );
  },
  methods: {
    returnBackClick() {
      this.$router.push({
        path: "/projectDetail",
        query: {
          id: this.datasetParams.itemId,
        },
      });
    },
    filteredLabelList(labelList) {
      return labelList.map((item) => {
        return item.labelName + ";";
      });
    },
    handleKeyDown(event) {
      // 阻止事件冒泡
      event.stopPropagation();
      //console.log(event);
      //console.log("你按下了：", event.key);
      if (event.key == "d" || event.key == "ArrowRight") {
        this.nextClick();
      } else if (event.key == "a" || event.key == "ArrowLeft") {
        this.previousClick();
      }
    },
    async getLabelList() {
      await selectLabelTypeByType(this.datasetParams.itemId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.labelList = res.data;
        }
      });
    },
    async getData() {
      await selectFirstUnannotatedText(this.datasetParams.itemId).then(
        (res) => {
          //console.log("--------");
          //console.log(res);
          if (res.code == 200) {
            this.textLabelDetails = res.data;
          } else if (res.code == 701) {
            this.emptyData = true;
            this.description = "暂无未标注数据";
          }
        }
      );
    },
    async getDataById(id) {
      await selectSequencedPacketExchangeByCondition(
        id,
        this.datasetParams.itemId
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.textLabelDetails = res.data;
          this.selectedLabel = {};
          res.data?.sequencedPacketExchangeByLabelerList.forEach((item) => {
            if (item.labeler == this.userId) {
              item.sequencedPacketExchangeVoList.forEach((item1) => {
                this.selectedLabel[item1.labelTypeName] = item1.itemLabelId;
              });
            }
          });
          //console.log(this.selectedLabel);
          this.getUnanotatedTextCount();
        }
      });
    },
    async saveDimension() {
      let result;
      this.dimensionParams.itemId = this.datasetParams.itemId;
      this.dimensionParams.textId = this.textLabelDetails.textId;
      let array = [];
      for (let prop in this.selectedLabel) {
        if (this.selectedLabel.hasOwnProperty(prop)) {
          array.push(this.selectedLabel[prop]);
        }
      }
      //console.log(array);
      this.dimensionParams.itemLabelIds = array;
      await addSequencedPacketExchange(this.dimensionParams).then((res) => {
        result = res;
      });
      return result;
    },
    async previousClick() {
      //console.log(this.selectedLabel);
      if (Object.keys(this.selectedLabel).length > 0) {
        let res = await this.saveDimension();
        //console.log(res);
        if (res.code != 200) {
          return;
        }
      }
      this.selectedLabel = {};
      await selectPreviousAnnotatedText(
        this.datasetParams.itemId,
        this.textLabelDetails.textId
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.textLabelDetails = res.data;
          res.data.sequencedPacketExchangeByLabelerList.forEach((item) => {
            if (item.labeler == this.userId) {
              item.sequencedPacketExchangeVoList.forEach((item1) => {
                this.selectedLabel[item1.labelTypeName] = item1.itemLabelId;
              });
            }
          });
          //console.log(this.selectedLabel);
          this.getUnanotatedTextCount();
        }
        // else if (res.code == 702) {
        //   this.emptyData = true;
        //   this.description = "已经是第一条了";
        // }
      });
    },
    async nextClick() {
      if (!this.selectedLabel || this.selectedLabel.length == 0) {
      } else {
        let res = await this.saveDimension();
        //console.log(res);
        if (res.code != 200) {
          return;
        }
      }
      this.selectedLabel = {};
      await selectNextUnannotatedText(
        this.datasetParams.itemId,
        this.textLabelDetails.textId
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.textLabelDetails = res.data;
          this.getUnanotatedTextCount();
        } else if (res.code == 701) {
          this.emptyData = true;
          this.description = "暂无未标注数据";
        }
      });
    },
    async getUnanotatedTextCount() {
      await selectUnannotatedTextCount(this.datasetParams.itemId).then(
        (res) => {
          //console.log(res);
          if (res.code == 200) {
            this.total = res.data;
          }
        }
      );
    },
    resetClick() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.sequence-classification-annotation-wrapper {
  width: 100%;
  height: 100%;
}

.retrieval-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.retrieval-container {
  display: flex;
  align-items: center;
}

.label-title {
  white-space: nowrap;
}

.sequence-classification-annotation {
  width: 100%;
  display: flex;
}

.label-list-wrapper {
  width: 30%;
}

.text-content-wrapper {
  width: 70%;
  margin-right: 50px;
}

.label-general-title-wrapper,
.text-conten-title-wrapper {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 550;
}

.label-wrapper {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.label-title-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.label-select-wrapper {
  margin-bottom: 20px;
}
</style>