<template>
  <div class="role-manage-wrapper">
    <div class="role-manage-button-wrapper">
      <el-button type="primary" icon="el-icon-plus" @click="addClick(1)"
        >新增</el-button
      >
    </div>
    <div class="role-table-wrapper">
      <el-table :data="tableData" border height="95%" style="width: 100%">
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称" width="180">
        </el-table-column>
        <el-table-column prop="roleKey" label="角色标识" width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0">有效</el-tag>
            <el-tag type="info" v-if="scope.row.status == 1">失效</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <!-- <el-button type="text" style="margin-right: 10px"
                ><i class="el-icon-view"></i>查看</el-button
              > -->
              <el-button
                type="text"
                style="margin-right: 20px"
                @click="editClick(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button type="text" @click="deleteClick(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="roleDialogTitle"
        :visible.sync="RoleDialogVisible"
        width="30%"
        @open="openRoleDialog"
        @closed="closeRoleDialog"
      >
        <div class="outermost-container">
          <div class="content-container">
            <div class="label-title-container">角色名称</div>
            <el-input
              placeholder="请输入角色名称"
              v-model="changeRoleInfo.roleName"
            ></el-input>
          </div>
          <div class="content-container">
            <div class="label-title-container">角色标识</div>
            <el-input
              placeholder="请输入角色标识"
              v-model="changeRoleInfo.roleKey"
            ></el-input>
          </div>
          <div class="content-container">
            <el-radio v-model="changeRoleInfo.status" :label="0" border
              >有效</el-radio
            >
            <el-radio v-model="changeRoleInfo.status" :label="1" border
              >无效</el-radio
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="RoleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRoleClick">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getAllRoleData,
  updateRole,
  addRole,
  deleteRole,
  getRoleById,
} from "@/api/role";
export default {
  data() {
    return {
      RoleDialogVisible: false,
      tableData: [],
      roleParams: {
        pageNum: 1,
        pageSize: 100,
        roleKey: "",
        roleName: "",
        status: 0,
        startTime: "",
        endTime: "",
      },
      changeRoleInfo: {
        roleKey: "",
        roleName: "",
        status: 0,
      },
      flag: 0,
    };
  },
  mounted() {
    this.getRolePage();
  },
  computed: {
    roleDialogTitle() {
      let title = "";
      if (this.flag == 1) {
        title = "新增角色";
      } else if (this.flag == 2) {
        title = "编辑角色";
      }
      return title;
    },
  },
  methods: {
    addClick(val) {
      this.flag = val;
      this.RoleDialogVisible = true;
    },
    editClick(item) {
      this.flag = 2;
      this.RoleDialogVisible = true;
      this.getRoleDataById(item);
    },
    deleteClick(item) {
      this.$confirm("是否要删除此角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteRole(item.roleId).then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getRolePage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async getRolePage() {
      await getAllRoleData(this.roleParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
    async addRoleClick() {
      let query;
      if (this.flag == 1) {
        query = {
          roleKey: this.changeRoleInfo.roleKey,
          roleName: this.changeRoleInfo.roleName,
        };
        await addRole(query).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.RoleDialogVisible = false;
            this.getRolePage();
          }
        });
      } else if (this.flag == 2) {
        await updateRole(this.changeRoleInfo).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.RoleDialogVisible = false;
            this.getRolePage();
          }
        });
      }
    },
    async openRoleDialog() {},
    closeRoleDialog() {
      this.changeRoleInfo = {
        roleKey: "",
        roleName: "",
        status: 0,
      };
    },
    async getRoleDataById(item) {
      await getRoleById(item.roleId).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.changeRoleInfo = { ...res.data };
        }
      });
    },
  },
};
</script>

<style scoped>
.role-manage-wrapper {
  width: 100%;
  height: 100%;
}

.role-manage-button-wrapper {
  width: 100%;
}

.role-table-wrapper {
  margin-top: 20px;
  height: 90%;
}

.content-container {
  margin-bottom: 20px;
}

.label-title-container {
  margin-bottom: 5px;
  font-size: 16px;
}
</style>