<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dataSetDialogVisible"
      width="60%"
      @open="handleDialogOpen"
      :before-close="closedDataSetDialog"
      @closed="handleClosedDialog"
    >
      <div class="dataSet-content-dialog">
        <div class="line-wrapper">
          <div class="line-title">标注文本</div>
          <el-input
            type="textarea"
            placeholder="请输入标注文本"
            v-model="textDetails.text"
            :disabled="flag == 2"
            ref="textarea"
          ></el-input>
        </div>
        <div
          class="line-wrapper"
          v-if="
            getLabelNameList(textDetails.sequencedPacketExchangeVoList).length >
            0
          "
        >
          <div class="line-title">标签</div>
          <el-tag
            style="margin-left: 5px"
            v-for="(item, index) in getLabelNameList(
              textDetails.sequencedPacketExchangeVoList
            )"
            :key="index"
            >{{ item.labelName }}</el-tag
          >
        </div>
        <div class="line-wrapper">
          <div class="line-title">标注详情</div>
          <div class="label-details-table-wrapper">
            <el-table
              :data="textDetails.sequencedPacketExchangeVoList"
              border
              style="width: 100%"
            >
              <el-table-column prop="labelName" label="标签" width="180">
              </el-table-column>
              <el-table-column prop="labelerName" label="标注人" width="180">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.labelerName
                    }}<span
                      style="color: #67c23a; margin-left: 5px"
                      v-if="scope.row.deleted == 0 && textDetails.isAudit == 1"
                      >已被同意</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="labelTime" label="标注时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="line-title">
            是否一致：<span v-if="textDetails.isConsistent == 1">是</span
            ><span v-else>否</span>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="line-title">
            审核状态：<span v-if="textDetails.isAudit == 1">已审核</span
            ><span v-if="textDetails.isAudit == 0">未审核</span>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="line-title">
            审核人：<span>{{ textDetails.auditorName }}</span>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="line-title">
            审核时间：<span>{{ textDetails.auditTime }}</span>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="line-title">
            创建时间：<span>{{ textDetails.createTime }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closedDataSetDialog">取 消</el-button>
        <el-button type="primary" @click="sureClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectSequencedPacketExchangeAnnotatedTextById,
  updateSequencedPacketExchangeAnnotatedTextById,
} from "@/api/dataSet";
export default {
  data() {
    return {
      text: "",
      textDetails: {},
      changeParams: {
        textId: 0,
        itemId: 0,
        text: "",
        annotationType: 0,
      },
    };
  },
  props: {
    dataSetDialogVisible: {
      type: Boolean,
      default: false,
    },
    textId: {
      type: Number,
      default: null,
    },
    flag: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.adjustTextareaHeight();
    });
  },
  watch: {
    "textDetails.text": function () {
      this.$nextTick(() => {
        this.adjustTextareaHeight();
      });
    },
  },
  computed: {
    title() {
      if (this.flag == 1) {
        return "编辑数据集";
      } else if (this.flag == 2) {
        return "查看数据集";
      }
    },
  },
  methods: {
    adjustTextareaHeight() {
      const textarea = this.$refs.textarea.$refs.textarea;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
    },
    getLabelNameList(value) {
      //console.log(value);
      let array = [];
      value?.forEach((item) => {
        if (this.textDetails.isAudit == 1) {
          if (item.deleted == 0) {
            array.push(item);
          }
        } else {
          array.push(item);
        }
      });
      return array;
    },
    closedDataSetDialog() {
      this.$emit("closedDataSetDialog");
    },
    sureClick() {
      if (this.flag == 1) {
        this.changeText();
      }
      this.closedDataSetDialog();
    },
    handleClosedDialog() {
      this.textDetails = {};
    },
    handleDialogOpen() {
      this.getDetail();
    },
    async getDetail() {
      await selectSequencedPacketExchangeAnnotatedTextById(this.textId).then(
        (res) => {
          //console.log(res);
          if (res.code == 200) {
            this.textDetails = res.data;
          }
        }
      );
    },
    async changeText() {
      this.changeParams.textId = this.textId;
      this.changeParams.text = this.textDetails.text;
      this.changeParams.annotationType = this.textDetails.annotationType;
      this.changeParams.itemId = this.textDetails.itemId;
      await updateSequencedPacketExchangeAnnotatedTextById(
        this.changeParams
      ).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.closedDataSetDialog();
        }
      });
    },
  },
};
</script>

<style scoped>
.line-wrapper {
  margin-bottom: 15px;
}
.line-title {
  margin-bottom: 5px;
}
.el-textarea {
  overflow: hidden;
}
</style>