//引入axios
import axios from "axios";
import router from "../router";
import { Message } from "element-ui";
// let baseURL = "https://annotationapi.pydance.cn"
// let baseURL = "http://192.168.0.229:8499"
// export const uploadURL = "http://192.168.0.229:8499";
// export const uploadURL = "http://172.18.100.4:8499";
let baseURL = process.env.VUE_APP_SERVER_PATH
export const uploadURL = process.env.VUE_APP_UPLOAD_PATH;
export const imageURL = process.env.VUE_APP_IMG_PATH;

//创建axios实例
export const request = axios.create({
  baseURL: baseURL, //公共地址
  timeout: 10000,//请求超时时间
  headers: {
    "Content-Type": "application/json",
  }, //请求头，可以不写
});
// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    let token = localStorage.getItem('token')
    if (token) {
      config.headers['Authentication'] = 'Bearer ' + token // token
    }
    // debugger
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (res) {
    //console.log(res);
    if (res.data?.code == 200) {
      return res.data
    } else if (res.data?.code == 701 || res.data?.code == 702 || res.data?.code == 703 || res.data?.code == 704) {
      Message({
        message: res.data.message,
        type: 'warning',
      })
      return res.data
    }
    else if (res.data.code == "11017" || res.data.code == "11011" || res.data.code == "11012" || res.data.code == "11013" || res.data.code == "11014" || res.data.code == "11015" || res.data.code == "11016" || res.data.code == "11018") {
      router.push('/login');
    } else {
      Message({
        message: res.data.message ? res.data.message : "系统异常，请联系管理员！",
        type: 'error',
      })
      return res.data
    }
    return res.data
  },
  function (error) {
    // 对响应错误做点什么
    return error;
  }
);
