import { request } from "@/requests";

export const selectLabelType = (data) => {
    return request({
        url: '/annotation/labelType/selectLabelType',
        method: 'post',
        data: data
    })
}

export const addLabelType = (data) => {
    return request({
        url: '/annotation/labelType/addLabelType',
        method: 'post',
        data: data
    })
}

export const updateLabelType = (data) => {
    return request({
        url: '/annotation/labelType/updateLabelType',
        method: 'post',
        data: data
    })
}

export const deleteLabelType = (data) => {
    return request({
        url: '/annotation/labelType/deleteLabelType',
        method: 'post',
        data: data
    })
}

export const selectAllLabelType = (data) => {
    return request({
        url: '/annotation/labelType/selectAllLabelType/' + data,
        method: 'get'
    })
}


// 查询项目的所有标签--按照标签类型分类
export const selectLabelTypeByType = (data) => {
    return request({
        url: '/annotation/labelType/selectLabelTypeByType/' + data,
        method: 'get'
    })
}