<template>
  <div class="userInfo_wrapper">
    <el-form
      :label-position="labelPosition"
      label-width="100px"
      :model="userBaseInfoData"
      style="max-width: 50%"
    >
      <el-form-item label="头像" v-if="flag == 1">
        <el-upload
          class="upload-demo"
          :show-file-list="false"
          :headers="headers"
          :on-success="handleUploadSuccess"
          drag
          :action="action"
          multiple
          name="avatar"
        >
          <el-avatar shape="square" :size="150" :fit="fill" :src="avatar" />
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="用户名">
        <el-input v-model="userInfoData.username" disabled />
      </el-form-item> -->
      <el-form-item label="姓名" v-if="flag == 1">
        <el-input
          v-model="userBaseInfoData.username"
          placeholder="请输入姓名"
        />
      </el-form-item>
      <el-form-item label="手机号" v-if="flag == 1">
        <el-input
          v-model="userBaseInfoData.phoneNumber"
          placeholder="请输入手机号"
        />
      </el-form-item>

      <el-form-item label="邮箱" v-if="flag == 1">
        <el-input v-model="userBaseInfoData.email" placeholder="请输入邮箱" />
      </el-form-item>
      <el-form-item label="原密码" v-if="flag == 2">
        <el-input
          type="password"
          show-password
          v-model="userBaseInfoData.oldPassword"
          placeholder="请输入原始密码"
        />
      </el-form-item>
      <el-form-item label="新密码" v-if="flag == 2">
        <el-input
          type="password"
          show-password
          v-model="userBaseInfoData.againPassword"
          placeholder="请输入新密码"
        />
      </el-form-item>
      <el-form-item label="确认密码" v-if="flag == 2">
        <el-input
          type="password"
          show-password
          v-model="userBaseInfoData.newPassword"
          placeholder="请确认密码"
        />
      </el-form-item>
      <div class="button_wrapper">
        <el-button type="primary" @click="changeInfo"> 提交 </el-button>
        <el-button @click="resetInfoClick"> 重置 </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { uploadURL, imageURL } from "@/requests";
import {
  updateMyUserBaseInfo,
  updateMyUserPassword,
  getMyInfo,
} from "@/api/user";
export default {
  data() {
    return {
      flag: 0,
      labelPosition: "right",
      fill: "fill",
      avatar: "",
      userBaseInfoData: {
        username: "",
        phoneNumber: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        againPassword: "",
      },
      headers: {
        Authentication: "Bearer " + localStorage.getItem("token"),
      },
    };
  },
  mounted() {
    this.flag = this.$route.query.flag;
    this.getuserInfo();
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        this.flag = query.flag;
        //console.log(query);
      },
    },
  },
  methods: {
    async changeInfo() {
      let query;
      if (this.flag == 1) {
        query = {
          username: this.userBaseInfoData.username,
          phoneNumber: this.userBaseInfoData.phoneNumber,
          email: this.userBaseInfoData.email,
        };
        await updateMyUserBaseInfo(query).then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.$router.push("/login");
          }
        });
      } else if (this.flag == 2) {
        if (
          this.userBaseInfoData.newPassword !=
          this.userBaseInfoData.againPassword
        ) {
          this.$message.error("两次输入的密码不一致，请重试");
          return;
        }
        query = {
          oldPassword: this.userBaseInfoData.oldPassword,
          newPassword: this.userBaseInfoData.newPassword,
        };
        //console.log(query);
        await updateMyUserPassword(query).then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.$router.push("/login");
          }
        });
      }
    },
    async getuserInfo() {
      await getMyInfo().then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.userBaseInfoData = { ...res.data };
          this.avatar = imageURL + this.userBaseInfoData.avatar;
        }
      });
    },
    handleUploadSuccess(response, file, fileList) {
      //console.log(response);
      if (response.code == 200) {
        this.$message.success("上传成功");
        this.getuserInfo();
      } else if (res.code == 11011) {
        this.$message.error("token失效");
        this.$router.push("/login");
      } else {
        this.$message.error(response.message);
      }
    },
    resetInfoClick() {
      this.userBaseInfoData = {
        username: "",
        phoneNumber: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        againPassword: "",
      };
    },
  },
  computed: {
    action() {
      return uploadURL + "/system/sysUser/uploadAvatar";
    },
  },
};
</script>

<style scoped>
.userInfo_wrapper {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.button_wrapper {
  margin-top: 20px;
  margin-left: 30px;
}
:deep(.el-upload-dragger) {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>