<template>
  <div class="annotation-wrapper">
    <AnnotattionList :annotationType="annotationType"></AnnotattionList>
  </div>
</template>

<script>
import AnnotattionList from "@/components/AnnotattionList.vue";
export default {
  data(){
    return {
      annotationType:1
    }
  },
  components:{
    AnnotattionList
  }
};
</script>

<style scoped>
.annotation-wrapper {
  width: 100%;
  height: 100%;
}
</style>