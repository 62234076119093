<template>
  <div class="data-set-wrapper">
    <div class="query-operation-wrapper">
      <div class="queryCriteria-wrapper">
        <div class="label-title">姓名</div>
        <el-input
          placeholder="请输入姓名"
          v-model="projectPersonParams.username"
        ></el-input>
      </div>
      <div class="queryCriteria-wrapper">
        <div class="label-title">角色</div>
        <el-select
          v-model="projectPersonParams.itemRoleId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in roleOptions"
            :key="item.itemRoleId"
            :label="item.itemRoleName"
            :value="item.itemRoleId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="query-button-wrapper">
        <el-button type="primary" @click="queryClick">查询</el-button>
        <el-button type="primary" style="margin-left: 30px" @click="resetClick"
          >重置</el-button
        >
      </div>
    </div>
    <div class="operation-button-wrapper">
      <el-button type="primary" icon="el-icon-plus" @click="AddPrejectPerson"
        >添加</el-button
      >
    </div>
    <div class="table-data-wrapper">
      <el-table :data="tableData" border height="90%" style="width: 100%">
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="username" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="itemRoleName" label="角色"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div class="custom-cell">
              <!-- <el-button
                type="text"
                style="margin-right: 10px"
                @click="viewUserClick(scope.row.userId)"
                ><i class="el-icon-view"></i>查看</el-button
              > -->
              <el-button
                type="text"
                style="margin-right: 20px"
                @click="editUserClick(scope.row)"
                ><i class="el-icon-edit"></i>编辑</el-button
              >
              <el-button
                type="text"
                @click="deleteUserClick(scope.row)"
                style="margin-right: 20px"
                ><i class="el-icon-delete"></i>删除</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.itemRoleId == 1"
                @click="transferClick(scope.row)"
                ><i class="el-icon-set-up"></i>转移管理员身份</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @opened="openDialogHandle"
      @closed="closeDialogHandle"
      width="25%"
    >
      <div class="dialog-content-wrapper">
        <div class="dialog-line-wrapper">
          <div class="dialog-line-label-tile">姓名：</div>
          <el-select
            v-model="addPersonParams.userId"
            filterable
            placeholder="请选择"
            :disabled="flag == 2"
          >
            <el-option
              v-for="item in personOptions"
              :key="item.userId"
              :label="item.username"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-line-wrapper" v-if="flag != 3">
          <div class="dialog-line-label-tile">角色：</div>
          <el-select
            v-model="addPersonParams.itemRoleId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.itemRoleId"
              :label="item.itemRoleName"
              :value="item.itemRoleId"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSureClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectPerson } from "@/api/user";
import { getAllProjectRole } from "@/api/projectRole.js";
import {
  selectItemPerson,
  addItemUserRole,
  updateRoleByItemIdAndUserId,
  deleteItemUserRole,
  transferAdmin,
} from "@/api/projectPerson.js";
import paginationView from "@/components/PaginationView.vue";
export default {
  data() {
    return {
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      flag: 0,
      roleParams: {
        pageNum: 1,
        pageSize: 100,
        itemRoleKey: "",
        itemRoleName: "",
      },
      addPersonParams: {
        itemId: 0,
        userId: "",
        itemRoleId: "",
      },
      tableData: [],
      roleOptions: [],
      personOptions: [],
      projectPersonParams: {
        pageNum: 1,
        pageSize: 10,
        itemId: 0,
        username: "",
        itemRoleId: "",
      },
      dialogVisible: false,
      title: "",
      projectId: 0,
    };
  },
  components: {
    paginationView,
  },
  watch: {
    "$route.query": {
      handler: async function (query) {
        //console.log(query);
        this.projectId = Number(query.id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getRoleList();
    this.getProjectPerson();
  },
  methods: {
    changePaginationSize(val) {
      this.projectPersonParams.pageSize = val;
      this.getProjectPerson();
    },
    changePaginationNum(val) {
      this.projectPersonParams.pageNum = val;
      this.getProjectPerson();
    },
    AddPrejectPerson() {
      this.title = "新增人员";
      this.dialogVisible = true;
      this.flag = 1;
    },
    async getRoleList() {
      this.roleOptions = [];
      await getAllProjectRole(this.roleParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          res.data?.list.forEach((item) => {
            if (item.itemRoleId == 1) {
              item.disabled = true;
            }
            this.roleOptions.push(item);
          });
          //console.log(this.roleOptions);
        }
      });
    },
    async getProjectPerson() {
      this.projectPersonParams.itemId = this.projectId;
      await selectItemPerson(this.projectPersonParams).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
          this.paginationData.pageSize = res.data.pageSize;
        }
      });
    },
    async dialogSureClick() {
      if (this.flag == 1) {
        this.addPersonParams.itemId = this.projectId;
        await addItemUserRole(this.addPersonParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.dialogVisible = false;
            this.getProjectPerson();
          }
        });
      } else if (this.flag == 2) {
        await updateRoleByItemIdAndUserId(this.addPersonParams).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.dialogVisible = false;
            this.getProjectPerson();
          }
        });
      } else if (this.flag == 3) {
        await transferAdmin(this.addPersonParams.userId, this.projectId).then(
          (res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "转移成功!",
              });
              this.dialogVisible = false;
              this.$router.push({
                path: "/sequenceClassification",
              });
            }
          }
        );
      }
    },
    editUserClick(row) {
      this.flag = 2;
      this.title = "编辑人员";
      this.dialogVisible = true;
      //console.log(row);
      this.addPersonParams.userId = String(row.userId);
      this.addPersonParams.itemId = row.itemId;
      this.addPersonParams.itemRoleId = row.itemRoleId;
    },
    deleteUserClick(row) {
      //console.log(row);
      let params = {
        itemId: row.itemId,
        userId: row.userId,
        itemRoleId: row.itemRoleId,
      };
      this.$confirm("是否要删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteItemUserRole(params).then((res) => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getProjectPerson();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    transferClick(row) {
      //console.log(row);
      this.flag = 3;
      this.title = "转移身份";
      this.dialogVisible = true;
    },
    async openDialogHandle() {
      await getProjectPerson("").then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.personOptions = res.data;
        }
      });
    },
    closeDialogHandle() {
      this.addPersonParams = {
        itemId: 0,
        userId: "",
        itemRoleId: "",
      };
    },
    queryClick() {
      this.getProjectPerson();
    },
    resetClick() {
      this.projectPersonParams = {
        pageNum: 1,
        pageSize: 10,
        username: "",
        itemRoleId: "",
      };
      this.getProjectPerson();
    },
  },
};
</script>

<style scoped>
.data-set-wrapper {
  width: 100%;
  height: 100%;
}

.query-operation-wrapper {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.queryCriteria-wrapper {
  display: flex;
  margin-right: 30px;
  align-items: center;
}
.label-title {
  white-space: nowrap; /* 防止换行 */
  margin-right: 10px;
}

.operation-button-wrapper {
  margin: 20px 0;
}

.table-data-wrapper {
  height: 85%;
  overflow-y: scroll;
}

.dialog-content-wrapper {
  width: 100%;
  height: 100%;
}

.dialog-line-wrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
</style>