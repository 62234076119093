import Vue from "vue";
import {
  Container,
  Aside,
  Header,
  Main,
  Message,
  MessageBox,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Button,
  BreadcrumbItem,
  Breadcrumb,
  Avatar,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Input,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Upload,
  Select,
  Option,
  Radio,
  FormItem,
  Form,
  Tag,
  Switch,
  Tabs,
  TabPane,
  DatePicker,
  Popconfirm,
  RadioGroup,
  Empty,
  Progress,
} from "element-ui";

Vue.use(Container);
Vue.use(Aside);
Vue.use(Header);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Button);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Avatar);
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dropdown)
Vue.use(Input)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Tag)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(DatePicker)
Vue.use(TabPane)
Vue.use(RadioGroup)
Vue.use(Popconfirm)
Vue.use(Empty)
Vue.use(Progress)
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
