import { request } from "@/requests";

// 人员标注详情统计
export const selectSequencedPacketExchangeByTextId = (data) => {
    return request({
        url: '/annotation/labeleDataStatistics/labelerDataStatistics/' + data,
        method: 'get'
    })
}

// 人员分配详情统计
export const personnelAssignment = (data) => {
    return request({
        url: '/annotation/labeleDataStatistics/personnelAssignment/' + data,
        method: 'get'
    })
}

// 人员分配详情统计
export const labelDistribution = (data) => {
    return request({
        url: '/annotation/labeleDataStatistics/labelDistribution/' + data,
        method: 'get'
    })
}

// 标签人标注情况统计
export const labelerLabelStatistics = (data) => {
    return request({
        url: '/annotation/labeleDataStatistics/labelerLabelStatistics/' + data,
        method: 'get'
    })
}

// 标签异常情况统计
export const labelAbnormal = (data) => {
    return request({
        url: '/annotation/labeleDataStatistics/labelAbnormal/' + data,
        method: 'get'
    })
}