<template>
  <div class="login_wrapper">
    <div class="container">
      <div class="logo">派动标注系统</div>
      <div class="login-item">
        <div class="form form-login">
          <div class="form-field">
            <label
              ><i class="el-icon-user-solid" style="color: #3a3f44"></i
            ></label>
            <input
              id="login-username"
              type="text"
              class="form-input"
              placeholder="用户名"
              v-model="phone"
            />
          </div>

          <div class="form-field" v-if="flag == 1">
            <label><i class="el-icon-lock" style="color: #3a3f44"></i></label>
            <input
              id="login-password"
              type="password"
              class="form-input"
              placeholder="密码"
              show-password
              v-model="password"
            />
          </div>

          <div class="form-field" v-if="flag == 0">
            <label
              ><i class="el-icon-message" style="color: #3a3f44"></i
            ></label>
            <input
              id="login-password"
              type="text"
              class="form-input"
              placeholder="验证码"
              v-model="phoneCode"
            />
            <label style="padding: 0"
              ><el-button
                @click="startCountdown"
                :disabled="counting"
                class="code_btn"
              >
                {{ buttonOrCountdownText }}
              </el-button></label
            >
          </div>

          <div class="select-loginMethon">
            <div @click="changeLoginMethon(0)">手机验证码登录</div>
            <div class="jiange">|</div>
            <div @click="changeLoginMethon(1)">账号密码登录</div>
          </div>

          <div class="form-field">
            <input type="submit" value="登  录" @click="loginClick" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import preventBack from "vue-prevent-browser-back";
import { loginByPhone, getPhoneCode, loginByPassword } from "@/api/login";
export default {
  data() {
    return {
      flag: 0,
      counting: false,
      countdown: 60, // 设置倒计时秒数
      phone: "",
      password: "",
      phoneCode: "",
    };
  },
  mixins: [preventBack], //阻止返回上一页
  computed: {
    buttonOrCountdownText() {
      return this.counting ? `重新发送 ${this.countdown}s` : "发送验证码";
    },
  },
  methods: {
    changeLoginMethon(value) {
      this.flag = value;
      this.phone = ""
      this.password = ""
      this.phoneCode = ""
      this.counting = false
      this.countdown = 60
    },
    async loginClick() {
      let param = {};
      if (this.flag == 0) {
        param = {
          phoneNumber: this.phone,
          smsCode: this.phoneCode,
        };
        await loginByPhone(param).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            localStorage.setItem("token",res.data.tokenValue)
            this.$router.push("/home");
          }
        });
      } else if (this.flag == 1) {
        param = {
          phoneNumber: this.phone,
          password: this.password,
        };
        await loginByPassword(param).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            localStorage.setItem("token",res.data.tokenValue)
            this.$router.push("/home");
          }
        });
      }
    },
    startCountdown() {
      this.getphoneCode();
    },
    async getphoneCode() {
      let param = {
        phoneNumber: this.phone,
        type: "login",
      };
      await getPhoneCode(param).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          if (!this.counting) {
            this.counting = true;
            const countdownInterval = setInterval(() => {
              this.countdown--;
              if (this.countdown < 0) {
                clearInterval(countdownInterval);
                this.counting = false;
                this.countdown = 60; // 重置倒计时
              }
            }, 1000);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.login_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(165deg, #5e72e3 50%, #ffffff 10%);
}

.container {
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  box-shadow: 0 1.5px 0 0.5px rgba(0, 0, 0, 0.1);
  width: 550px;
  display: flex;
  flex-direction: column;
}

.logo {
  font-size: 25px;
  text-align: center;
  padding: 20px 20px 0;
  margin: 0;
  font-weight: 500;
  color: #222222;
}

.login-item {
  color: #ffff;
  padding: 25px 25px 0;
  margin: 20px 20px 0;
  border-radius: 3px;
}

input {
  height: 100%;
  border: 0;
  color: inherit;
  font: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.user:before {
  content: "\f007";
  font: 14px fontawesome;
  color: #5b5b5b;
}

.lock:before {
  content: "\f023";
  font: 14px fontawesome;
  color: #5b5b5b;
}

.form input[type="password"],
.form input[type="text"],
.form input[type="submit"] {
  width: 100%;
}

.form-login label,
.form-login input[type="text"],
.form-login input[type="password"],
.form-login input[type="submit"] {
  border-radius: 0.25rem;
  padding: 1rem;
  color: #3a3f44;
}

.form-login label {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.form-login input[type="text"],
.form-login input[type="password"] {
  background-color: #ffffff;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
/* .form-login input[type="text"]:focus,
.form-login input[type="text"]:hover,
.form-login input[type="password"]:focus,
.form-login input[type="password"]:hover {
  background-color: #eeeeee;
} */
.form-login input[type="submit"] {
  background-color: #5e72e3;
  color: #eee;
  font-weight: bold;
  text-transform: uppercase;
}
.form-login input[type="submit"]:focus,
.form-login input[type="submit"]:hover {
  background-color: #5e72e3;
}
.form-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  margin-bottom: 2rem;
  height: 100%;
  border-radius: 5px;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.form-field label,
.form-field input {
  display: inline-block; /* 设置为内联块级元素 */
  white-space: nowrap; /* 防止换行 */
}

.code_btn {
  height: 100%;
}
.text--center {
  text-align: center;
}

.select-loginMethon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  cursor: pointer;
  color: #222222;
}

.jiange {
  margin: 0 20px;
}
</style>